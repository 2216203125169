.iconContainer {
  height: 66px;
  width: 66px;
  padding: 12px;
  border-radius: 15px;
  background: #fafafa;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.iconArea {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 8px;
  border: 1px solid #d3d3d3;
  background: #fff;
  cursor: pointer;
}
