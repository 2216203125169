$font-size-lg: 18px;
$font-line-height-lg: 26px;
$font-size-md: 13px;
$font-line-height-md: 22px;
$font-size-sm: 12px;
$font-line-height-sm: 18px;
$font-weight: 400;
$light_gray: #f0f0f0;
$line_light_grey: #d3d3d3;
$form_box_radius: 8px;
hr {
  margin: 1rem 0.5rem;
  color: #f0f0f0;
}
body {
  font-family: 'Manrope', sans-serif;
  font-weight: 400;
  font-size: $font-size-md !important;
  // color: #4C4C4C;
  line-height: 22px;
  letter-spacing: 0.03em;
}
.wrap {
  max-width: 1392px;
  margin: 0 auto;
  width: 100%;
  // position: relative;
}
.mt50 {
  padding-top: 50px;
}
.mb50 {
  margin-bottom: 50px;
}
.mt100 {
  padding-top: 100px;
}
.mt76 {
  padding-top: 76px !important;
}
.pt45 {
  padding-top: 45px !important;
}
.w450 {
  width: 450px;
}
.w100 {
  width: 100%;
}
.max-w650,
.max-w450,
.max-w800,
.max-w1000 {
  max-width: 650px;
  width: 90%;
  margin: 0 auto;
}
.max-w450 {
  max-width: 550px;
  width: 100%;
}
.max-w800 {
  max-width: 800px;
  width: 100%;
}
.max-w1000 {
  max-width: 1000px;
  width: 100%;
}
.modal .modal-header {
  align-items: flex-start;
}
.mx-30 {
  margin-left: 30px;
  margin-right: 30px;
}
.module-py-15 {
  padding-top: 15px;
  padding-bottom: 15px;
}
.modal-header .btn-close {
  margin: 0;
  padding: 0;
}
.custom-modal-title {
  padding-top: 25px;
  padding-left: 30px;
  padding-right: 30px;
}
.btn-close {
  width: 25px;
  height: 25px;
}
.img-hero {
  max-width: 100%;
  height: auto;
  width: 60% !important;
}
.row > * {
  padding-left: 0.45rem;
  padding-right: 0.45rem;
}

.btn_default_xl,
.btn_default_lg,
.btn_default_sm,
.btn_default_md,
.btn_gray_sm,
.btn_light_gray_sm,
.btn_light_gray_md {
  background: #f59700;
  border-radius: 50px;
  text-decoration: none;
  color: #fff !important;
  text-align: center;
  border: none;
  white-space: nowrap;
}
.btn_default_xl:hover,
.btn_default_lg:hover,
.btn_default_sm:hover,
.btn_default_md:hover,
.btn_primary_md:hover,
.btn_gray_lg:hover,
.btn_gray_sm:hover,
.btn_light_gray_sm:hover,
.btn_light_gray_md {
  text-decoration: none;
}
.btn_default_xl,
.btn_outline_xl {
  padding: 15px 38px !important;
  font-weight: $font-weight;
  font-size: $font-size-lg;
  line-height: $font-line-height-lg;
}
.btn_default_md,
.btn_outline_md,
.btn_light_gray_md {
  padding: 9px 27px !important;
  font-size: $font-size-md;
  line-height: $font-line-height-md;
}
.btn_default_lg,
.btn_outline_lg {
  padding: 8px 37px !important;
  font-size: $font-size-md;
  line-height: $font-line-height-md;
}
.btn_default_sm,
.btn_outline_sm,
.btn_gray_sm,
.btn_light_gray_sm,
.btn_outline_gray_sm,
.btn_outline_spec_sm {
  padding: 3px 15px !important;
  font-weight: 400;
  font-size: $font-size-sm;
  line-height: $font-line-height-sm;
}
.btn_outline_gray_sm {
  color: #4c4c4c !important;
  border: 1px solid #4c4c4c !important;
  background-color: #fff !important;
  border-radius: 50px;
}
.btn_outline_spec_sm {
  color: #f59700 !important;
  border: 1px solid #f59700 !important;
  background-color: #fff !important;
  border-radius: 50px;
}
.btn.btn_outline_spec_sm:focus:not(.btn-active).dropdown-toggle:after,
.btn.btn_outline_spec_sm:hover:not(.btn-active).dropdown-toggle:after,
.btn.btn_outline_spec_sm:active:not(.btn-active).dropdown-toggle:after,
.btn.btn_outline_spec_sm.active.dropdown-toggle:after,
.btn.btn_outline_spec_sm.show.dropdown-toggle:after,
.show > .btn.btn_outline_spec_sm.dropdown-toggle:after {
  color: #f59700 !important;
}
.btn.btn_outline_spec_sm.dropdown-toggle:after {
  color: #f59700 !important;
}
.btn_gray_sm {
  background: #4c4c4c;
}
.btn_light_gray_sm,
.btn_light_gray_md {
  background: #f0f0f0;
  color: #4c4c4c !important;
}
.btn_outline_md,
.btn_outline_lg {
  line-height: $font-line-height-md - 4px;
}
.btn_outline_sm {
  line-height: $font-line-height-sm - 4px;
}
.btn_outline_xl {
  line-height: $font-line-height-lg - 4px;
}
.btn_outline_md,
.btn_outline_xl,
.btn_outline_lg,
.btn_outline_sm,
.btn_gray_lg {
  border: 2px solid #f59700;
  color: #f59700 !important;
  background: #fff;
  border-radius: 50px !important;
  text-decoration: none;
  text-align: center;
  white-space: nowrap;
}

.btn_gray_lg {
  border: 2px solid #f0f0f0;
  background: #f0f0f0;
  padding: 15px 38px !important;
  color: #4c4c4c !important;
}
.btn_gray_lg:hover {
  color: #fff !important;
  background: #f59700;
  border: 2px solid #f59700;
}
.btn_outline_sm {
  border-width: 1px;
}
.btn_outline_md:hover,
.btn_outline_xl:hover,
.btn_outline_lg:hover {
  color: #fff !important;
  background: #f59700;
  text-decoration: none;
}
.drop_zone_style {
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.05);
  border-radius: 15px;
  border: none;
}
.input_area {
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.05) !important;
  border-radius: $form_box_radius;
  background-color: #fff !important;
  border: 1px solid $line_light_grey;
  padding: 14px 25px;
}
.input_area:hover {
  border: 1px solid rgba(76, 76, 76, 0.2) !important;
}
.input_area:focus-visible {
  outline: none;
}
.text-area-label::before {
  content: '';
  position: absolute;
  top: 1px; /* border-width (default by BS) */
  left: 1px; /* border-width (default by BS) */
  width: calc(100% - 35px); /* to show scrollbar and error logo */
  height: 24px;
  border-radius: 15px 0 0 15px;
  background-color: #ffffff;
}

/* Start Font Hierarchy */
h1,
.h1 {
  //Title 01
  font-weight: 700;
  font-size: 40px;
  line-height: 52px;
}
h2,
.h2 {
  //Title 02
  font-weight: 700;
  font-size: 30px;
  line-height: 42px;
}
h3,
.h3 {
  //Title 03
  font-weight: 700;
  font-size: 18px;
  line-height: 26px;
}
.subtitle {
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
}
.custom-modal .modal-content {
  padding: 30px;
}
.bodybold {
  font-weight: 700 !important;
  font-size: 13px !important;
  line-height: 22px !important;
}

.body {
  font-size: 13px;
  line-height: 22px;
}
.bodylarge {
  font-size: 18px !important;
  line-height: 26px;
}
.bodybig {
  font-size: 16px !important;
  line-height: 24px;
}
.small {
  font-size: 12px;
  line-height: 18px;
}
.overline {
  // font-size: 10px;
  font-weight: 500;
  font-size: 12px;
  transform: scale(0.83, 0.83);
  line-height: 14px;
}
/* End Font Hierarchy */
.btn_dashed {
  border-radius: 50px;
  border: 1px dashed #4c4c4c;
}
.bg_light_gray {
  background: rgba(246, 246, 246, 0.5);
}
.w-85 {
  width: 85% !important;
}
.border-gray {
  border: 1px solid rgba(76, 76, 76, 0.05) !important;
}
.border-radius-sm {
  border-radius: 15px;
}
.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.icon-file-pdf {
  background: url(../img/icons_new/PDF.svg) no-repeat 0 0;
  width: 26px;
  height: 30px;
  display: inline-block;
  background-size: 80%;
}
.h85 {
  height: 85px;
}
ul {
  list-style-type: none;
}
.w-30 {
  width: 30% !important;
}
.w-45 {
  width: 45% !important;
}
.light-color {
  color: #f59700;
}
.default-color {
  color: #4c4c4c;
}
.default-color-light {
  color: rgba(76, 76, 76, 0.3);
}
.form-switch-check {
  .form-check-input {
    height: 22px;
  }
  .form-check-label {
    margin-top: 2px;
  }
}
.form-control:disabled {
  background-color: #f6f6f6 !important;
}
.input_border_gray {
  border: 1px solid rgba(76, 76, 76, 0.2);
}
.btn_square_sm,
.btn_square_md {
  padding: 3px 10px;
  border: 1px solid #4c4c4c;
  border-radius: 5px;
  background: #fff;
}
.btn_square_sm {
  font-weight: 400;
  font-size: $font-size-sm;
  line-height: $font-line-height-sm;
}

.btn_square_sm-outline,
.btn_square_md-outline {
  background-color: transparent;
  color: #f59700;
  border: 1px solid #f59700;
}
.btn_square_sm-outline:hover,
.btn_square_md-outline:hover {
  color: #e68f01;
  border: 1px solid #e68f01;
}

.w_100 {
  width: 100% !important;
}

.icon {
  width: 20px;
  display: inline-block;
  text-align: center;
  height: 20px;
}
.icon_template,
.icon_reminder,
.icon_download,
.icon_delete,
.icon_view,
.icon_mouse,
.icon_folder_edit,
.icon_move,
.icon_shared,
.icon_remove,
.icon_file,
.icon_share_people,
.icon_new_folder,
.drop_icon_profile,
.drop_icon_temp,
.drop_icon_dash,
.drop_icon_set,
.drop_icon_notice,
.drop_icon_admin,
.drop_icon_logout,
.icon_list,
.icon_restore,
.icon_edit,
.icon_copy,
.icon_view_details,
.icon_transfer,
.icon_close,
.icon_unlock,
.icon_eye,
.icon_close_white,
.icon_cut,
.icon_resetpsd,
.icon_send_white,
.icon_arrowDown_solid {
  display: inline-block;
  width: 20px;
  height: 20px;
  vertical-align: middle;
  background: url(../img/icons_new/icon_PDF.svg) no-repeat;
  background-size: cover;
}
.icon_close_white {
  background: url(../img/icons_new/icon_close_white.svg) no-repeat center;
  background-size: cover;
  width: 12px;
  height: 13px;
  cursor: pointer;
}
.icon_arrowDown_solid {
  background: url(../img/icons_new/arrow-down-solid.svg) no-repeat 0 center;
  background-size: cover;
}
.icon_send_white {
  background: url(../img/icons_new/send_white.svg) no-repeat 0 center;
  background-size: cover;
}
.icon_resetpsd {
  background: url(../img/icons_new/icon_resetpsd.svg) no-repeat -1px center;
  background-size: cover;
}
.icon_cut {
  background: url(../img/icons_new/icon_cut.svg) no-repeat center;
}
.icon_eye {
  background: url(../img/icons_new/icon_eye.svg) no-repeat center;
  background-size: contain;
}
.icon_unlock {
  background: url(../img/icons_new/icon_unlock.svg) no-repeat center;
  background-size: cover;
}
.icon_view_details {
  background: url(../img/icons_new/icon_view_detail.svg) no-repeat center -1px;
  background-size: cover;
}
.icon_transfer {
  background: url(../img/icons_new/icon_transfer.svg) no-repeat center 0;
  background-size: cover;
}
.icon_copy {
  background: url(../img/icons_new/icon_copy.svg) no-repeat center 0;
  background-size: cover;
}
.icon_edit {
  background: url(../img/icons_new/icon_edit.svg) no-repeat center 0;
  background-size: cover;
}
.icon_share_people {
  background: url(../img/icons_new/icon_shared_people.svg) no-repeat;
  background-size: cover;
}
.icon_file {
  background: url(../img/icons_new/icon_folder.svg) no-repeat;
  background-size: cover;
}
.icon_new_folder {
  background: url(../img/icons_new/icon_add_folder.svg) no-repeat;
  background-size: cover;
}
.icon_move {
  background: url(../img/icons_new/icon_move.svg) no-repeat center 0;
  background-size: 90%;
}
.icon_remove {
  background: url(../img/icons_new/icon_remove.svg) no-repeat center 0;
  background-size: cover;
  cursor: pointer;
}
.icon_folder_edit {
  background: url(../img/icons_new/icon_edit_folder.svg) no-repeat center -2px;
  background-size: 88%;
}
.icon_mouse {
  background: url(../img/icons_new/icon_mouse.svg) no-repeat center 0;
  background-size: cover;
}
.icon_reminder {
  background: url(../img/icons_new/icon_reminder.svg) no-repeat;
  background-size: cover;
}
.icon_download {
  background: url(../img/icons_new/icon_download.svg) no-repeat 0 -2px;
  background-size: cover;
}
.icon_delete {
  background: url(../img/icons_new/cancel.svg) no-repeat;
  background-size: cover;
  cursor: pointer;
}
.icon_view {
  background: url(../img/icons_new/icon_view.svg) no-repeat 0 -1px;
  background-size: cover;
}
.icon_shared {
  background: url(../img/icons/share-icon.svg) no-repeat center 0;
  background-size: 85%;
}
.icon_restore {
  background: url(../img/icons_new/icon_restore.svg) no-repeat center 0;
  background-size: cover;
}
.icon_close {
  background-size: cover;
  background: url(../img/icons_new/close_grey.svg) no-repeat center;
  cursor: pointer;
}
.drop_icon_profile {
  background: url(../img/landing_icons/icon_profile.svg) no-repeat -2px center;
  background-size: cover;
}
.drop_icon_temp {
  background: url(../img/landing_icons/icon_templates.svg) no-repeat;
  background-size: cover;
}
.drop_icon_dash {
  background: url(../img/landing_icons/icon_dashboard.svg) no-repeat;
  background-size: cover;
}
.drop_icon_set {
  background: url(../img/landing_icons/icon_settings.svg) no-repeat;
  background-size: cover;
}
.drop_icon_notice {
  background: url(../img/landing_icons/icon_notification.svg) no-repeat;
  background-size: cover;
}
.drop_icon_admin {
  background: url(../img/icons_new/superuser.svg) no-repeat -2px center;
  background-size: cover;
}
.drop_icon_logout {
  background: url(../img/landing_icons/icon_logout.svg) no-repeat;
  background-size: cover;
}
.form-floating > .form-control:not(:placeholder-shown) ~ label,
.form-floating > .form-control:focus ~ label,
.form-floating > .form-control-plaintext ~ label,
.form-floating > .form-select ~ label {
  padding-left: 27px;
}
.form-floating > .form-control,
.form-floating > .form-control-plaintext {
  padding: 1rem 2rem;
}
.form-floating > .form-control,
.form-floating > .form-control-plaintext,
.form-floating > .form-select {
  height: 50px;
  line-height: 1.25;
  padding: 1rem 25px;
}

.form-floating > label {
  color: #9e9e9e;
  padding: 14px 25px;
}
.form-floating .input_area {
  min-height: 52px;
}
.icon_list {
  background: url(../img/icons_new/icon_drag_list.svg) no-repeat 0 center;
  width: 24px;
  height: 20px;
  background-size: cover;
}
.textarea-floating-label {
  label {
    background: #fff;
    height: 23px;
    top: 9px;
    left: 1px;
    border-radius: 8px;
    padding-top: 5px;
    padding-bottom: 0;
    display: block;
    width: 99%;
    padding-left: 22px !important;
    line-height: 12px;
  }
}
.textarea-floating-label[disabled] {
  label {
    background: #f6f6f6 !important;
  }
}
.textarea-floating-label > .form-control:focus ~ label,
.textarea-floating-label > .form-control:not(:placeholder-shown) ~ label,
.textarea-floating-label > .form-control-plaintext ~ label,
.textarea-floating-label > .form-select ~ label {
  opacity: 1;
  color: #9e9e9e;
  transform: translateY(-0.5rem) translateX(0.15rem);
  font-size: 11.9px;
  padding-left: 30px;
}
.textarea-floating-label > .form-control:focus,
.textarea-floating-label > .form-control:not(:placeholder-shown),
.textarea-floating-label > .form-control-plaintext:focus,
.textarea-floating-label > .form-control-plaintext:not(:placeholder-shown) {
  padding-top: 23px;
}
.input_area_spec {
  border-radius: 0;
  border: none;
  border-bottom: 1px solid #4c4c4c;
  background: rgba(246, 246, 246, 0.5);
}
.dropdown-menu {
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.05) !important;
  border-radius: 15px !important;
  padding: 20px 8px !important;
  border: none !important;
}
.whole-loading {
  background: #fff;
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  padding-top: 350px;
  left: 0;
  z-index: 100;
  bottom: 0;
  right: 0;
  overflow: hidden;
}
.navbar-toggler:focus {
  box-shadow: none;
  outline: none;
}
.navbar-toggler {
  border: none;
  border-radius: none;
  padding-right: 0;
}
.dropdown-menu {
  z-index: 2000;
}
.dropdown-item:hover,
.dropdown-item:focus {
  background-color: #fff8d5;
}

.dropdown-item:not(:last-child) {
  margin-bottom: 0.5rem !important;
}

.navbar-toggler-icon {
  background-image: url(../img/icons_new/menu_icon.svg);
  width: 2.2rem;
  height: 2.2rem;
}
.offcanvas-header .btn-close {
  background: url(../img/icons_new/close_icon.svg) no-repeat;
  opacity: 1;
  margin-right: 10px !important;
}
.question .accordion-button {
  align-items: flex-start;
}
#basic-navbar-nav .dropdown-toggle::after {
  display: none;
}
.doc_status {
  border-radius: 5px !important;
  min-width: 82px;
  text-align: center;
}
.green_badge {
  color: rgb(49, 168, 54) !important;
  border: 1px solid rgb(49, 168, 54) !important;
}
.table_header > div {
  background: #f0f0f0;
  /* height: 25px; */
  border-right: 3px solid #fff;
}
.btn_outline_gray_sm.dropdown-toggle::after {
  color: #4c4c4c !important;
}
@media (max-width: 1400px) {
  .offcanvas-top {
    height: 100vh !important;
  }
  .wrap {
    padding-left: 26px !important;
    padding-right: 26px !important;
  }
}
@media (max-width: 1000px) {
  .tem_header {
    font-size: 26px;
    line-height: 40px;
  }
  .img-hero {
    width: 70% !important;
  }
}
@media (max-width: 700px) {
  .img-hero {
    width: 80% !important;
  }
}
@media (max-width: 575px) {
  .col-sm-12 {
    flex: 0 0 auto !important;
    width: 100% !important;
  }
  .img-hero {
    width: 90% !important;
  }
}

.clickable {
  cursor: pointer;
}

.sun-editor {
  font-family: 'Manrope', sans-serif;
  border: none;
}

.sun-editor,
.sun-editor .se-btn-module-border {
  border-radius: 15px;
}

.sun-editor .se-btn-tray {
  padding: 20px;
}

.sun-editor .se-toolbar {
  outline: none;
  border-radius: 15px 15px 0px 0px;
}

.sun-editor .se-btn {
  width: 27px;
  height: 27px;
  border-radius: 15px;
}

.sun-editor button .txt {
  margin-top: -2px;
}

.sun-editor .se-btn-module:not(:last-child) {
  margin-right: 10px;
}

.sun-editor .se-btn-module {
  margin-top: 8px;
}

.sun-editor .se-resizing-bar .se-navigation,
.sun-editor .se-resizing-bar {
  border: none;
  border-radius: 0px 0px 15px 15px;
  padding: 5px 15px 5px 15px;
}

.sun-editor .se-dialog .se-dialog-inner .se-dialog-content {
  border-radius: 15px;
}

.sun-editor .se-wrapper {
  background-color: #fafafa;
}

.sun-editor .se-wrapper .se-wrapper-inner {
  margin: 0px 25px;
  border-radius: 15px;
  width: revert;
}

.sun-editor .se-wrapper .se-wrapper-code {
  width: 96%;
}

.sun-editor .se-wrapper .se-placeholder {
  padding: 25px 0px 0px 50px;
}

.sun-editor-editable {
  padding: 25px;
}

.sun-editor .se-dialog .se-dialog-inner .se-dialog-content .se-btn-primary {
  background: #f59700;
  border-radius: 50px;
  text-decoration: none;
  color: #fff !important;
  text-align: center;
  border: none;
  white-space: nowrap;
  padding: 9px 27px !important;
  font-size: 13px;
  line-height: 22px;
}

.sun-editor .se-dialog .se-dialog-inner .se-dialog-form .se-input-form {
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.05) !important;
  border-radius: 15px;
  background-color: #fff !important;
  border: 1px solid #fff;
}

.sun-editor
  .se-dialog
  .se-dialog-inner
  .se-dialog-form
  .se-input-form.se-math-exp {
  padding: 20px 35px;
  // padding: 1rem 2rem;
}

// .infinite-scroll-component {
//   min-height: 300px;
// }

.sun-editor-editable div > table,
.sun-editor-editable div > table tr,
.sun-editor-editable div > table th,
.sun-editor-editable div > table td {
  border: none !important;
}

// antd table
.custom-table .ant-table table {
  font-size: $font-size-md;
}

.custom-table .ant-table-column-title {
  z-index: inherit;
}

.custom-table .ant-table-thead > tr > th.text-center div {
  justify-content: center;
}

.custom-table .ant-table-thead > tr > th {
  padding: 0 16px !important;
  background: #f0f0f0 !important;
  border-right: 3px solid #fff;
}

.custom-table .ant-table-thead > tr > th,
.custom-table .ant-table-tbody > tr > td {
  color: #4c4c4c !important;
}

.custom-table .ant-table-tbody > .ant-table-row > td {
  padding-top: 25px !important;
  padding-bottom: 25px !important;
}

.custom-table .ant-table-thead > tr > th:first-child {
  border-start-start-radius: 0 !important;
}

.custom-table .ant-table-thead > tr > th:last-child {
  border-right: none;
  border-start-end-radius: 0 !important;
}

.custom-table .ant-table-row.ant-table-row-selected > .ant-table-cell {
  background-color: #fff !important;
}

.custom-table
  .ant-table-row.ant-table-row-selected
  > .ant-table-cell.ant-table-column-sort {
  background-color: #fafafa !important;
}

.custom-table
  .ant-table-row.ant-table-row-selected
  > .ant-table-cell.ant-table-cell-row-hover {
  background-color: #fff8d5 !important;
}

.custom-table .ant-table-row-expand-icon-collapsed,
.custom-table .ant-table-row-expand-icon-expanded {
  border: 1px solid #4c4c4c !important;
}

.custom-table .ant-table-row-expand-icon::before,
.custom-table .ant-table-row-expand-icon::after {
  background: #4c4c4c !important;
}

.ant-dropdown-trigger:after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: '';
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}

// .custom-table .ant-table-tbody > tr:hover > td {
//   background: #fff !important;
// }

// .custom-table .ant-table-column-sort {
//   background: #fff !important;
// }

.custom-table .ant-table-column-sort {
  background: #fff !important;
}

// antd select
.ant-select {
  height: auto !important;
}
.ant-select-small.filter-options1 .ant-select-selector {
  border-radius: 15px 0px 0px 15px !important;
}

.ant-select-small.filter-options2 .ant-select-selector {
  border-radius: 0px 15px 15px 0px !important;
}

.ant-select-item-option-content {
  padding: 5px 12px !important;
}

.ant-select-selector,
.ant-select-small .ant-select-selector {
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.05) !important;
  border-radius: $form_box_radius !important;
  background-color: #fff !important;
  border: 1px solid $line_light_grey !important;
  padding: 10px 25px !important;
  min-height: 52px !important;
}
/*small size ant-select*/
.ant-select-small .ant-select-selector {
  padding: 5px 15px !important;
  min-height: 40px !important;
}
.ant-select-small .ant-select-selection-item {
  text-align: left;
}
.ant-select-small .ant-select-item-option-content {
  padding: 5px 0px !important;
}

.ant-select-dropdown {
  box-shadow: none !important;
  border: 1px solid #d3d3d3 !important;
  padding: 12px 0 !important;
  z-index: 1200;
}
.ant-select-item-option-active,
.ant-select-item-option-selected {
  background-color: #fff8d5 !important;
  border-radius: 0 !important;
  font-weight: normal !important;
}

// tabs
.tab_list {
  border-bottom: 1px solid rgba(76, 76, 76, 0.2);
}
.tab_list button[class='nav-link'],
.tab_list button[class='nav-link active'] {
  border: none;
}
.tab_list button[class='nav-link active'] {
  border-bottom: 2px solid #f59700;
  border-color: #f59700 !important;
  color: #f59700 !important;
  min-width: 135px;
}
.tab_list button[class='nav-link'] {
  color: #4c4c4c;
  min-width: 135px;
}

// select with search
.select-with-search input[type='search'] {
  margin: 4px 0 !important;
  height: 20px !important;
}

.ant-select-multiple .ant-select-arrow span,
.select-with-search .ant-select-arrow span {
  background: url(../img/arrow-down.png) no-repeat center center;
  background-size: 0.9rem;
  height: 8px;
  width: 15px;
  display: block;
}
.ant-select-multiple .ant-select-arrow svg,
.select-with-search .ant-select-arrow svg {
  display: none;
}

// antd dropdown
.ant-dropdown-menu.ant-dropdown-menu-root {
  background-color: #4c4c4c;
  border-radius: 15px;
  padding: 20px 8px;
}

.ant-dropdown-menu-title-content {
  font-size: $font-size-md;
  color: #fff;
}
.ant-dropdown-menu-item-disabled .ant-dropdown-menu-title-content {
  color: #a2a6b8;
}
.ant-dropdown-menu-item-divider {
  background-color: #fff !important;
}

.ant-dropdown-menu-item {
  border-radius: 8px !important;
  padding: 6px 12px !important;
  min-width: 150px;
}

.ant-dropdown-menu-item:hover {
  background-color: #fff8d5 !important;
  .ant-dropdown-menu-title-content {
    color: #3a3b4c;
  }
}
.ant-dropdown-menu-item-disabled:hover {
  .ant-dropdown-menu-title-content {
    color: #a2a6b8;
  }
}
.custom-dropdown-menu .ant-dropdown-menu {
  max-height: 50vh;
  overflow-y: auto;
  // z-index: 2000;
}
