.content-wrap {
  background: rgba(246, 246, 246, 0.5);
  border-radius: 15px;
  padding: 50px 40px;
}
.content-width {
  max-width: 860px;
  margin: 0 auto; 
}
.mb_big {
  margin-bottom: 160px;
}