.card_icon {
  width: 70px !important;
  margin: 0 auto;
}
.about_card {
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.05) !important;
  border-radius: 15px !important;
  margin: 0 auto;
  height: 366px !important;
}
/*contract*/
.contact_wrap .form-input-area {
  padding: 20px 35px !important;
  width: 100%;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.05) !important;
  border-radius: 15px !important;
  border: none !important;
}
/*footer*/
.footer_area {
  background-color: #4c4c4c;
  padding: 49px 0 60px;
  color: #fff;
}
.footer_area .nav a {
  color: #fff !important;
  padding-left: 0;
  padding: 5px 0 !important;
}
.spec_input {
  background: #4c4c4c;
  border: 1px solid #919191;
  border-radius: 50px;
  height: 48px;
  flex-wrap: nowrap !important;
  align-items: center !important;
}
.spec_input input,
.spec_input input:focus {
  background: none;
  border: none;
  color: #fff;
  padding-left: 25px;
}
.spec_input button,
.spec_input button:focus {
  background: #f0f0f0;
  border-radius: 50px !important;
  color: #4c4c4c;
  width: 80px;
  height: 35px;
  margin-right: 5px;
  padding: 0 !important;
  font-size: 13px;
}
.media_area i {
  font-size: 25px;
  color: #fff;
  margin-right: 25px;
}
/*Gotop*/
.go_top {
  width: 80px;
  height: 80px;
  border-radius: 80px;
  background: rgba(240, 240, 244, 0.8);
  position: fixed;
  right: calc(50% - 696px);
  bottom: 50px;
  text-align: center;
  font-size: 28px;
  padding-top: 25px;
  cursor: pointer;
  z-index: 1000 !important;
}

/*introduce*/
.introduce_wrap {
  color: #fff;
  background: url(../../assets/img/bg_introduce.png) no-repeat center 0;
  background-color: #4c4c4c;
  background-size: cover;
  padding: 115px 80px 80px;
}
.introduce_wrap .introduce_right,
.introduce_wrap .introduce_left {
  padding: 0 80px 0 0 !important;
}
.introduce_wrap .introduce_right {
  padding-left: 80px !important;
  padding-right: 0px !important;
}
.introduce_wrap dl dt {
  background: url(../../assets/img/landing_icons/tick_icon.svg) no-repeat 0 -1px;
  color: #f59700;
  padding-left: 45px;
}
.introduce_wrap dl dd {
  margin-bottom: 38px;
  padding-left: 45px;
}

/*parter*/
.partnerIcon_0,
.partnerIcon_1,
.partnerIcon_2 {
  background-color: transparent;
  border-radius: 50%;
  filter: grayscale(100);
  opacity: 0.5;
  transition: all 0.5s ease-in-out;
  width: 180px;
  display: inline-block;
  margin-right: 80px;
}
.partnerIcon_0:hover,
.partnerIcon_1:hover,
.partnerIcon_2:hover {
  filter: grayscale(0);
  opacity: 1;
}
.partnersItems {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.partnerIcon_1 {
  margin-left: -25px;
  margin-right: 10px;
  width: 200px;
}
.partnerIcon_2 {
  margin-right: 0;
  width: 140px;
}

/*plan*/
.md_default_btn,
.md_spec_btn {
  border: 2px solid #f59700;
  border-radius: 50px;
  color: #f59700;
  background-color: #fff;
  padding: 9px 27px;
  text-decoration: none;
}
.md_default_btn:hover,
.md_spec_btn:hover {
  text-decoration: none;
}

.plan_card {
  box-sizing: border-box;
  /* max-width: 424px; */
  padding: 45px 15px;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.05) !important;
  border-radius: 15px !important;
  min-height: 1050px;
  border: 3px solid transparent !important;
  min-width: 300px !important;
  margin: 0 auto;
}
.plan_card:hover {
  border: 3px solid #f59700 !important;
  cursor: pointer;
}

.card_content {
  text-align: left;
}
.h36 {
  height: 36px;
}
.h50 {
  height: 50px;
}
.h65 {
  min-height: 65px;
}
.plan_price_wrap {
  border: 3px solid #4c4c4c;
  border-radius: 15px;
  /* max-width: 362px; */
  min-height: 140px;
  height: auto;
  text-align: center;
  padding: 5px;
  overflow: hidden;
  clear: both;
}
.plan_price_wrap .price {
  color: #f59700;
}
.plan_price_wrap .plan_spec {
  float: left;
  width: 50%;
  margin: 12px 0;
}
.plan_price_wrap .plan_spec:first-child {
  border-right: 1px solid #4c4c4c;
}

.plan_btn_area {
  text-align: center;
  margin-top: 24px;
  position: absolute;
  bottom: 60px;
  left: 50%;
  margin-left: -85px;
}
.plan_list li {
  background: url(../../assets/img/icons_new/check.png) no-repeat 0 center;
  padding-left: 32px;
}
.md_spec_btn {
  border: 2px solid #ffffff;
  color: #fff;
  background-color: #1c2124;
}
.small_btn_area {
  text-align: right;
  margin-bottom: 32px;
}
.small_btn {
  border: 1px solid #4c4c4c;
  color: #4c4c4c;
  font-size: 13px;
  line-height: 18px;
  border-radius: 5px;
  background-color: #fff;
  padding: 2px 10px;
}
.hide {
  display: none;
}
/*product*/
.product_image {
  border-radius: 15px;
  width: 100%;
}

.product_card {
  /* max-width: 364px; */
  background: rgba(255, 255, 255, 0.8) !important;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.05) !important;
  border-radius: 15px;
  padding: 50px 22px 40px;
  z-index: 10;
  margin: -100px 30px;
  /* height: 60% !important; */
}
.product_title {
  margin-bottom: 1.5rem !important;
}
.empty_block {
  height: 100px;
}
.card {
  min-width: 320px;
}

/*questions*/
.accordion_item {
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.05);
  border-radius: 15px;
  border: none;
}
.accordion_header button {
  background-color: #fff !important;
  color: #4c4c4c !important;
  padding: 1.25rem 2.18rem;
  border-radius: 15px;
  font-weight: 700;
  font-size: 14px;
  line-height: 22px;
  font-family: 'Manrope';
  letter-spacing: 0.03em;
}
.accordion_header button:focus {
  border: none !important;
  border-color: #fff;
  box-shadow: none !important;
}
.accordion_header button:not(.collapsed) {
  box-shadow: none !important;
}
.accordion_body {
  padding: 0 2.18rem 1.25rem;
}
.accordion_header button:not(.collapsed)::after {
  background-image: url(../../assets/img/arrow-down.png);
  background-size: 0.9rem;
  background-position: center center;
}

/*request demo*/
.request_demo {
  background: url(../../assets/img/bg_request_demo.png) no-repeat 0 0;
  background-size: contain;
  border-radius: 15px;
}

.request_demo_wrap {
  border-radius: 15px;
  color: #fff;
  background-color: #1e2528;
}
.md_spec_btn {
  border: 2px solid #ffffff;
  color: #fff;
  background-color: #1c2124;
}

/*solutions*/
.solutions_nav_item {
  width: 209px;
  height: 106px;
  background: #ffffff;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  margin-right: 27px;
}
.solutions_nav_item a {
  color: #4c4c4c !important;
  background-color: #fff !important;
  padding-left: 0;
  padding-right: 0;
}
.solutions_nav_link[aria-selected='true'],
.solutions_nav_link_mobile button[aria-expanded='true'] {
  color: #f59700 !important;
  background-color: #4c4c4c !important;
}
.solutions_nav_item:last-child {
  margin-right: 0;
}
.solutions_nav_link {
  display: block;
  color: #4c4c4c;
  height: 106px;
}

.solutions_icon_0,
.solutions_icon_1,
.solutions_icon_2,
.solutions_icon_3,
.solutions_icon_4,
.solutions_icon_5 {
  background-color: #4c4c4c;
  -webkit-mask-image: url(../../assets/img/landing_icons/icon_financial.svg);
  mask-image: url(../../assets/img/landing_icons/icon_financial.svg);
  width: 42px;
  height: 42px;
  margin: 10px auto 10px;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  mask-position: center;
  -webkit-mask-position: center;
  -webkit-mask-repeat: no-repeat;
}
.solutions_icon_1 {
  -webkit-mask-image: url(../../assets/img/landing_icons/icon_insurance.svg);
  mask-image: url(../../assets/img/landing_icons/icon_insurance.svg);
}
.solutions_icon_2 {
  -webkit-mask-image: url(../../assets/img/landing_icons/icon_life_sciences.svg);
  mask-image: url(../../assets/img/landing_icons/icon_life_sciences.svg);
}
.solutions_icon_3 {
  -webkit-mask-image: url(../../assets/img/landing_icons/icon_education.svg);
  mask-image: url(../../assets/img/landing_icons/icon_education.svg);
}
.solutions_icon_4 {
  -webkit-mask-image: url(../../assets/img/landing_icons/icon_government.svg);
  mask-image: url(../../assets/img/landing_icons/icon_government.svg);
}
.solutions_icon_5 {
  -webkit-mask-image: url(../../assets/img/landing_icons/icon_healthcare.svg);
  mask-image: url(../../assets/img/landing_icons/icon_healthcare.svg);
}
.solutions_nav_link[aria-selected='true'] .solutions_icon_0,
.solutions_nav_link[aria-selected='true'] .solutions_icon_1,
.solutions_nav_link[aria-selected='true'] .solutions_icon_2,
.solutions_nav_link[aria-selected='true'] .solutions_icon_3,
.solutions_nav_link[aria-selected='true'] .solutions_icon_4,
.solutions_nav_link[aria-selected='true'] .solutions_icon_5,
.solutions_nav_link_mobile button[aria-expanded='true'] .solutions_icon_0,
.solutions_nav_link_mobile button[aria-expanded='true'] .solutions_icon_1,
.solutions_nav_link_mobile button[aria-expanded='true'] .solutions_icon_2,
.solutions_nav_link_mobile button[aria-expanded='true'] .solutions_icon_3,
.solutions_nav_link_mobile button[aria-expanded='true'] .solutions_icon_4,
.solutions_nav_link_mobile button[aria-expanded='true'] .solutions_icon_5 {
  background-color: #f59700;
}
.solutions_tab_content_wrap_mobile {
  padding-top: 33%;
}

.solutions_list dt,
.solutions_list_mobile dt {
  vertical-align: top;
  background: url(../../assets/img/landing_icons/check_right.svg) no-repeat 0 -1px;
}

.solutions_tab_pane_0 {
  background: url(../../assets/img/bg_finance.svg) no-repeat right center;
  background-size: 44%;
}
.solutions_tab_pane_1 {
  background: url(../../assets/img/bg_insurance.svg) no-repeat right 0;
  background-size: 44%;
}
.solutions_tab_pane_2 {
  background: url(../../assets/img/bg_life_science.svg) no-repeat right 0;
  background-size: 44%;
}
.solutions_tab_pane_3 {
  background: url(../../assets/img/bg_education.svg) no-repeat right 0;
  background-size: 40%;
}
.solutions_tab_pane_4 {
  background: url(../../assets/img/bg_government.svg) no-repeat right 0;
  background-size: 42%;
}
.solutions_tab_pane_5 {
  background: url(../../assets/img/bg_healthcare.svg) no-repeat right 0;
  background-size: 43%;
}
.according_item .solutions_tab_pane_1,
.according_item .solutions_tab_pane_2,
.according_item .solutions_tab_pane_3,
.according_item .solutions_tab_pane_4,
.according_item .solutions_tab_pane_5 {
  background-position: center 20px;
  background-size: 42%;
}
.according_item .solutions_tab_pane_0 {
  background-position: center -30px;
  background-size: 42%;
}
.solutions_list_mobile {
  margin-bottom: 0;
}
.solutions_list_mobile dt {
  width: 100%;
}
.solutions_list_mobile dd {
  width: 100%;
  padding-left: 38px;
  margin-bottom: 20px;
}
.solutions_nav_link_mobile button {
  background: #ffffff;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.05) !important;
  border-radius: 10px !important;
  font-weight: 600;
  padding: 12px;
}
.solutions_nav_link_mobile .solutions_icon_0,
.solutions_nav_link_mobile .solutions_icon_1,
.solutions_nav_link_mobile .solutions_icon_2,
.solutions_nav_link_mobile .solutions_icon_3,
.solutions_nav_link_mobile .solutions_icon_4,
.solutions_nav_link_mobile .solutions_icon_5 {
  margin: 4px 20px 0;
  height: 34px;
}
.solutions_nav_link_mobile .solutions_icon_0 {
  margin-top: 0;
  height: 42px;
}
.solutions_nav_link_mobile .solutions_icon_1 {
  margin-top: 0;
  height: 42px;
}
.according_item {
  border: none !important;
}
/*verifyFile*/
.files_area {
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.05);
  border-radius: 15px;
  background: url(../../assets/img/bg_verifydoc.png) no-repeat -46px 60px;
  padding-top: 255px;
}
.list dt {
  background: url(../../assets/img/landing_icons/check_right.svg) no-repeat 0 0;
  padding-left: 38px;
  margin-bottom: 5px;
  background-size: 100%;
}
.list dd {
  padding-left: 36px;
}
.footer_line {
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  height: 1px;
}

/**
inside app footer
*/
.simple_footer {
  text-align: right;
  margin: 0;
}
.simple_footer li {
  display: inline-block;
  padding: 0 40px;
  border-right: 1px solid #909090;
}
.simple_footer li:last-child {
  padding-right: 0;
  border: none;
}
.contact_suc_bg {
  background: url(../../assets/img/contact_suc_bg.svg) no-repeat 190px 20px;
  background-size: 55%;
  height: 500px;
  padding: 335px 15% 100px;
}
.contact_modal {
  border-bottom: none !important;
}
@media (max-width: 1392px) {
  .go_top {
    right: 26px;
    width: 70px;
    height: 70px;
    padding-top: 20px;
  }
}
@media (max-width: 1200px) {
  .solutions_tab_pane_1,
  .solutions_tab_pane_2,
  .solutions_tab_pane_3,
  .solutions_tab_pane_4,
  .solutions_tab_pane_5 {
    background-position: right center;
  }
  .request_demo_wrap .request_demo_mt36 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }
  .contact_suc_bg {
    background: url(../../assets/img/contact_suc_bg.svg) no-repeat 30% 20px;
    background-size: 70%;
    height: 500px;
    padding: 290px 12% 0;
  }
}
@media (max-width: 992px) {
  .introduce_wrap {
    padding: 80px 40px;
  }
  .introduce_wrap .introduce_right {
    padding-left: 40px !important;
  }
  .introduce_wrap .introduce_left {
    padding-right: 40px !important;
  }
  .contact_suc_bg {
    background: url(../../assets/img/contact_suc_bg.svg) no-repeat -10px 20px;
    background-size: 90%;
    height: 480px;
    padding-top: 250px;
  }
  .simple_footer li {
    padding: 0 15px;
  }
}
@media (max-width: 900px) {
  .solutions_nav_item a {
    padding: 3px 0;
  }
  .card_icon {
    margin-bottom: 25px;
  }
  .col-sm-6 {
    flex: 0 0 auto !important;
    width: 50% !important;
  }
  .partnerIcon_1 {
    width: 120px;
    margin-left: 0;
    margin-right: -10px;
  }
  .partnerIcon_2 {
    width: 90px;
  }
  .partnerIcon_0 {
    width: 100px;
    margin-right: 15px;
  }

  .plan_describ {
    height: auto;
    line-height: 18px;
    margin-bottom: 0;
  }
  .plan_list {
    margin-bottom: 40px;
  }

  .btn_area {
    position: static;
    margin-left: 0;
  }
  .plan_card {
    min-height: auto;
    margin: 0 auto;
  }
  .product_card {
    padding: 30px 10px;
  }
  .card {
    margin-bottom: 50px;
  }
  .plan_btn_area {
    bottom: 30px;
  }
}
@media (max-width: 767px) {
  .h65 {
    min-height: 30px;
    height: auto;
  }
  .introduce_wrap {
    background-image: none;
    padding: 0;
    overflow: hidden;
  }
  .introduce_wrap .introduce_left {
    padding: 80px 40px 0 !important;
    background: url(../../assets/img/bg_intro_1.png) no-repeat center 66%;
    background-size: cover;
    background-color: #323438;
  }
  .introduce_wrap .introduce_right {
    padding: 50px 40px 0 !important;
    background-color: #2c3135;
  }
  .introduce_wrap .mobile_bg {
    /* background: url(../../assets/img/bg_into_2.png) no-repeat center; */
    background-color: #2c3135;
    background-size: cover;
    /* height: 420px; */
  }
  /* .introduce_wrap .mobile_bg img {
    width: 100%;
    height: auto;
  } */
  .request_demo {
    background: url(../../assets/img/bg_request_demo.png) no-repeat center 0;
    background-size: contain;
  }
  .request_demo_wrap {
    text-align: center;
  }
  .request_demo_wrap .request_demo_mt36 {
    margin-top: 36px !important;
    margin-bottom: 4rem !important;
  }
}

@media (max-width: 600px) {
  .h50 {
    height: auto;
  }
  .request_demo_wrap .request_demo_mt36 {
    padding: 0 20px !important;
    margin-top: 20px !important;
  }
  .col-sm-12 {
    flex: 0 0 auto !important;
    width: 100% !important;
  }
  .solutions_tab_content_wrap_mobile {
    padding-top: 53%;
  }
  .according_item .solutions_tab_pane_1,
  .according_item .solutions_tab_pane_2,
  .according_item .solutions_tab_pane_3,
  .according_item .solutions_tab_pane_4,
  .according_item .solutions_tab_pane_5 {
    background-position: center 20px;
    background-size: 65%;
  }
  .according_item .solutions_tab_pane_0 {
    background-position: center -30px;
    background-size: 65%;
  }
}

@media (max-width: 400px) {
  .request_demo_wrap .request_demo_mt36 {
    margin-top: 10px !important;
  }
  .according_item .solutions_tab_pane_1,
  .according_item .solutions_tab_pane_2,
  .according_item .solutions_tab_pane_3,
  .according_item .solutions_tab_pane_4,
  .according_item .solutions_tab_pane_5 {
    background-position: center 20px;
    background-size: 100%;
  }
  .according_item .solutions_tab_pane_0 {
    background-position: center -30px;
    background-size: 100%;
  }
  .solutions_tab_content_wrap_mobile {
    padding-top: 260px;
  }
  .contact_suc_bg {
    background: url(../../assets/img/contact_suc_bg.svg) no-repeat -30px 20px;
    background-size: 100%;
    height: 450px;
    padding-top: 210px;
  }
}
