/* -------------------------------------------------------------------------- */
/*   Sidebar                                                                  */
/* -------------------------------------------------------------------------- */

$item-height: 70px;
$item-width: 55px;
$item-image-width: 25px;
$item-height-active: 85px;
$item-width-active: 70px;
$item-image-width-active: 35px;

// Base
.sidebar {
  min-width: 350px;
  max-width: 350px;
  padding-top: 50px;
  padding-left: 46px;
  transition: all 0.3s;
  position: fixed;
  height: 100%;
  overflow-y: auto;

  .nav {
    flex-direction: column;
    font-size: $font-size-sm;
    font-weight: $font-weight-normal;

    .nav-item {
      font-weight: $font-weight-bold;
      text-transform: uppercase;
      color: $gray-400;
      padding-bottom: 0.8rem;
    }

    .nav-link {
      color: $gray-700;
      padding: 0.35rem 0.9rem;

      &:hover {
        color: $gray-1000;
      }

      &.active {
        font-weight: $font-weight-bolder;

        &:hover {
          color: $gray-700;
        }
      }
    }
  }
}

// Tablet & Mobile Modes
@include media-breakpoint-down(lg) {
  .sidebar {
    display: none;
  }
}
