.feature_area {
  background: rgba(246, 246, 246, 0.5) !important;
  border-radius: 15px;
  padding: 40px;
}
.feature_item {
  background: #ffffff;
  border: 1px solid rgba(76, 76, 76, 0.05);
  border-radius: 15px;
  padding: 17px 20px;
  height: 60px;
  display: flex;
  justify-content: space-between;
}
.table_row {
  padding: 20px 0;
  border-bottom: 1px solid #f0f0f0;
}
.mb_big {
  margin-bottom: 160px;
}
.content {
  margin-bottom: 110px !important;
}
.blastTo {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  padding: 9px 30px;
  background: rgba(246, 246, 246, 0.5);
  border-radius: 15px;
  width: 100%;
}
.blastToLeft {
  display: flex;
  gap: 20px;
  align-items: center;
}

.checkboxGroupLabel {
  white-space: nowrap;
  margin-bottom: 0 !important;
}
.checkboxGroup {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
  padding: 10px 40px;
  gap: 30px;
  border: 1px solid #d3d3d3;
  border-radius: 15px;
}
