.recipientContainer {
  background: rgba(246, 246, 246, 0.5);
  margin-bottom: 30px;
  padding: 25px;
}

.importedCountContainer {
  padding: 20px;
  border-radius: 15px;
  border: 1px solid #f0f0f0;
}

.innerImportedCountContainer {
  border-radius: 8px;
  background: rgba(246, 246, 246, 0.5);
  padding: 15px;
  display: flex;
  justify-content: center;
}

.uploadArea {
  width: 100%;
  height: 315px;
  border-radius: 8px;
  margin: 0 auto;
  padding: 20px;
  padding-top: 38px;
  background: #fff;
  border: 1px solid #d3d3d3;
}
.countryCode {
  box-shadow: none !important;
  border-radius: 8px 0 0 8px !important;
  background-color: #fff !important;
  border-color: #d3d3d3 !important;
}
