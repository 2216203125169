.tableNavigationContainer {
  display: flex;
  flex-direction: column;
  padding: 30px;
  border: 1px dashed #4c4c4c;
  border-radius: 15px;
  width: 100%;
  height: auto;
}

.current {
  width: 100%;
  height: 60px;
  display: flex;
  flex-direction: row;
  align-items: center;
  background: #4c4c4c;
  border-radius: 8px;
  text-decoration: none;
}

.current span {
  color: #f59700;
}

.tab {
  width: 100%;
  height: 60px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 20px;
}

.divider {
  margin: 10px;
  width: 100%;
  height: 0px;
  border-bottom: 1px solid #f0f0f0;
  /* transform: rotate(-0.21deg); */
}

.icon_feature,
.icon_plan,
.icon_notification {
  width: 20px;
  height: 20px;
  background-color: #4c4c4c;
  -webkit-mask-image: url(../../assets/img/icons_new/icon_brand.svg);
  mask-image: url(../../assets/img/icons_new/icon_brand.svg);
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  mask-size: cover;
  margin-left: 26px;
}
.icon_feature {
  -webkit-mask-image: url(../../assets/img/icons_new/icon_feature.svg);
  mask-image: url(../../assets/img/icons_new/icon_feature.svg);
}
.icon_plan {
  -webkit-mask-image: url(../../assets/img/icons_new/icon_plan.svg);
  mask-image: url(../../assets/img/icons_new/icon_plan.svg);
}
.icon_notification {
  -webkit-mask-image: url(../../assets/img/icons_new/icon_notification.svg);
  mask-image: url(../../assets/img/icons_new/icon_notification.svg);
}

.current .icon_feature,
.current .icon_plan,
.current .icon_notification {
  background-color: #f59700;
}
@media (max-width: 990px) {
  .tableNavigationContainer {
    padding: 15px;
  }
  .icon_feature,
  .icon_plan,
  .icon_notification {
    margin-left: 10px;
  }
  .tab span {
    margin-left: 10px;
  }
}
