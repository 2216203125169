.topbar {
  background-color: #fff;
  position: fixed;
  width: 100%;
  z-index: 1015;
}
.nus_logo {
  background: url(../../../assets/img/nus_logo.svg) no-repeat 16px 0;
  width: 105px;
  height: 40px;
  display: inline-block;
  border-left: 1px solid #d3d3d3;
  padding-left: 15px;
}
.logo {
  height: auto;
  width: 160px;
  min-width: 125px;
}
.spec_btn {
  width: 100%;
  border-radius: 0.475rem !important;
  max-width: 200px;
  height: 35px;
}
.navbar a {
  color: #4c4c4c;
}
.navbar a[class='px-0 bodybig mx-4 nav-link active'] {
  color: #f59700 !important;
  border-bottom: 2px solid #f59700;
}

.offcanvas[role='dialog'] {
  background-color: #434343;
}
.offcanvas[role='dialog'] .navbar > a {
  color: #fff !important;
  border-bottom: 1px solid rgba(255, 255, 255, 0.15);
  width: 80%;
  padding: 1rem 0 !important;
}
.offcanvas[role='dialog'] .navbar > a[role='button'] {
  padding: 9px 27px !important;
}
.offcanvas[role='dialog'] .navbar > a.nav-link:last-child {
  border-bottom: none;
}
.offcanvas[role='dialog'] .navbar .btn_default,
.offcanvas[role='dialog'] .navbar .btn_spec {
  width: 80%;
  margin-top: 1rem !important;
}
.offcanvas[role='dialog'] .navbar .btn_default {
  margin-top: 2rem !important;
  color: #f59700 !important;
  background: none;
  border: 2px solid #f59700;
  display: inline-block;
}
.userinfo_btn {
  width: 42px;
  height: 42px;
  background-color: #f59700;
  color: #ffffff !important;
  border-radius: 58px;
  padding: 0.6rem !important;
  display: inline-block;
  text-align: center;
  font-weight: bold;
  text-decoration: none !important;
}
.userinfo_btn::after {
  display: none !important;
}
.dropdown_area {
  padding: 10px;
  border-radius: 15px;
}
div[aria-labelledby='user-info'] {
  left: -152px !important;
  border: none;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.05) !important;
}

@media (max-width: 1200px) {
  /* a[id='user-info'] {
    width: 100%;
    text-align: center;
  } */
  .navbar .nav-item a:last-child {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
}

.arrow_left {
  font-size: 26px;
  vertical-align: middle;
}
@media (max-width: 700px) {
  .logo {
    height: auto;
    width: 125px;
  }
}

@media (max-width: 1400px) {
  .navbar a {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .offcanvas[role='dialog'] .navbar .mobile_btn {
    color: #f59700 !important;
    margin-bottom: 15px;
    margin-top: 35px;
    background-color: #434343;
    border: 2px solid #f59700;
    width: 80%;
  }
  .navbar .nav-item a {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  /* .navbar a:last-child {
    margin-right: 0 !important;
    margin-left: 0 !important;
  } */
}
@media (max-width: 413px) {
  .topbar .nus_logo {
    width: 80px;
    background-size: 92%;
    padding-left: 6px;
    background-position: 6px;
    margin-left: 6px !important;
  }
}