//*-----------------------------------------------
//|   Spinners
//-----------------------------------------------*/
.spinner-border-lg {
  width: 3rem;
  height: 3rem;
  border: 0.35em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: 0.75s linear infinite spinner-border;
  margin-top: 180px;
}

.spinner-border-xs {
  width: 0.92rem;
  height: 0.92rem;
  border: 0.15em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: 0.75s linear infinite spinner-border;
  margin-bottom: 1px;
}
