/*-----------------------------------------------
|   Button
-----------------------------------------------*/
.btn {
  // Reset outline
  outline: none !important;

  // Reset focus shadow
  &:not(.btn-shadow):not(.shadow):not(.shadow-sm):not(.shadow-lg) {
    box-shadow: none !important;
  }

  // Remove border
  &:not(.btn-outline):not(.btn-dashed):not(.border-hover):not(.border-active):not(.btn-flush):not(.btn-icon) {
    border: 0;
    padding: calc(#{$btn-padding-y} + #{$btn-border-width})
      calc(#{$btn-padding-x} + #{$btn-border-width});

    &.btn-lg {
      padding: calc(#{$btn-padding-y-lg} + #{$btn-border-width})
        calc(#{$btn-padding-x-lg} + #{$btn-border-width});
    }

    &.btn-sm {
      padding: calc(#{$btn-padding-y-sm} + #{$btn-border-width})
        calc(#{$btn-padding-x-sm} + #{$btn-border-width});
    }
  }

  // Link
  &.btn-link {
    border: 0;
    border-radius: 0;
    padding-left: 0 !important;
    padding-right: 0 !important;
    text-decoration: none;
    font-weight: $btn-font-weight;

    &:hover {
      text-decoration: underline;
    }
  }

  // Outline dashed
  &.btn-outline-dashed {
    border: 1px dashed $border-dashed-color;
  }

  // Flush
  &.btn-flush {
    @include button-reset();
  }

  // Flex
  &.btn-flex {
    display: inline-flex;
    align-items: center;
  }
}

// Icons
.btn {
  // Font icon
  i {
    display: inline-flex;
    font-size: $font-size-base;
    padding-right: 0.35rem;
    vertical-align: middle;
    line-height: 0;
  }

  // Svg icon
  .svg-icon {
    flex-shrink: 0;
    line-height: 0;
    margin-right: 0.5rem;
  }

  // Icon only button
  &.btn-icon {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    height: $input-height;
    width: $input-height;

    // Remove border
    &:not(.btn-outline):not(.btn-dashed):not(.border-hover):not(.border-active):not(.btn-flush) {
      border: 0;
    }

    // Sizes
    &.btn-sm {
      height: $input-height-sm;
      width: $input-height-sm;
    }

    &.btn-lg {
      height: $input-height-lg;
      width: $input-height-lg;
    }

    &.btn-circle {
      border-radius: 50%;
    }

    i,
    .svg-icon {
      padding: 0;
      margin: 0;
      line-height: 1;
    }
  }
}

// Custom colors
@each $name, $value in $theme-colors {
  // Base
  .btn.btn-#{$name} {
    $color: theme-inverse-color($name);
    $icon-color: theme-inverse-color($name);
    $border-color: $value;
    $bg-color: $value;

    $color-active: theme-inverse-color($name);
    $icon-color-active: theme-inverse-color($name);
    $border-color-active: theme-active-color($name);
    $bg-color-active: theme-active-color($name);

    @include button-custom-variant(
      $color,
      $icon-color,
      $border-color,
      $bg-color,
      $color-active,
      $icon-color-active,
      $border-color-active,
      $bg-color-active
    );
  }

  // Light
  @if (theme-light-color($name)) {
    .btn.btn-light-#{$name} {
      $color: $value;
      $icon-color: $value;
      $border-color: theme-light-color($name);
      $bg-color: theme-light-color($name);

      $color-active: theme-inverse-color($name);
      $icon-color-active: theme-inverse-color($name);
      $border-color-active: $value;
      $bg-color-active: $value;

      @include button-custom-variant(
        $color,
        $icon-color,
        $border-color,
        $bg-color,
        $color-active,
        $icon-color-active,
        $border-color-active,
        $bg-color-active
      );
    }
  }

  @if ($btn-extended-variants == true or $name == primary or $name == light) {
    .btn.btn-bg-#{$name} {
      $color: null;
      $icon-color: null;
      $border-color: $value;
      $bg-color: $value;

      $color-active: null;
      $icon-color-active: null;
      $border-color-active: null;
      $bg-color-active: null;

      @include button-custom-variant(
        $color,
        $icon-color,
        $border-color,
        $bg-color,
        $color-active,
        $icon-color-active,
        $border-color-active,
        $bg-color-active
      );
    }

    .btn.btn-active-#{$name} {
      $color: null;
      $icon-color: null;
      $border-color: null;
      $bg-color: null;

      $color-active: theme-inverse-color($name);
      $icon-color-active: theme-inverse-color($name);
      $border-color-active: $value;
      $bg-color-active: $value;

      @include button-custom-variant(
        $color,
        $icon-color,
        $border-color,
        $bg-color,
        $color-active,
        $icon-color-active,
        $border-color-active,
        $bg-color-active
      );
    }

    @if (theme-light-color($name)) {
      .btn.btn-active-light-#{$name} {
        $color: null;
        $icon-color: null;
        $border-color: null;
        $bg-color: null;

        $color-active: $value;
        $icon-color-active: $value;
        $border-color-active: theme-light-color($name);
        $bg-color-active: theme-light-color($name);

        @include button-custom-variant(
          $color,
          $icon-color,
          $border-color,
          $bg-color,
          $color-active,
          $icon-color-active,
          $border-color-active,
          $bg-color-active
        );

        // Don't change the border color for outline style
        &.btn-outline:not(.btn-outline-default) {
          border-color: $value !important;
        }
      }
    }
  }
}

// Custom text colors
@each $name, $value in $theme-text-colors {
  @if ($btn-extended-variants == true or $name == primary or $name == muted) {
    // Text and icon colors
    .btn.btn-color-#{$name} {
      $color: $value;
      $icon-color: $value;
      $bg-color: null;
      $border-color: null;

      $color-active: null;
      $icon-color-active: null;
      $border-color-active: null;
      $bg-color-active: null;

      @include button-custom-variant(
        $color,
        $icon-color,
        $border-color,
        $bg-color,
        $color-active,
        $icon-color-active,
        $border-color-active,
        $bg-color-active
      );
    }

    // Text and icon colors active state
    .btn.btn-active-color-#{$name} {
      $color: null;
      $icon-color: null;
      $border-color: null;
      $bg-color: null;

      $color-active: $value;
      $icon-color-active: $value;
      $border-color-active: null;
      $bg-color-active: null;

      @include button-custom-variant(
        $color,
        $icon-color,
        $border-color,
        $bg-color,
        $color-active,
        $icon-color-active,
        $border-color-active,
        $bg-color-active
      );
    }

    // Icon colors
    .btn.btn-icon-#{$name} {
      $color: null;
      $icon-color: $value;
      $bg-color: null;
      $border-color: null;

      $color-active: null;
      $icon-color-active: null;
      $border-color-active: null;
      $bg-color-active: null;

      @include button-custom-variant(
        $color,
        $icon-color,
        $border-color,
        $bg-color,
        $color-active,
        $icon-color-active,
        $border-color-active,
        $bg-color-active
      );
    }

    // Icon colors active state
    .btn.btn-active-icon-#{$name} {
      $color: null;
      $icon-color: null;
      $bg-color: null;
      $border-color: null;

      $color-active: null;
      $icon-color-active: $value;
      $border-color-active: null;
      $bg-color-active: null;

      @include button-custom-variant(
        $color,
        $icon-color,
        $border-color,
        $bg-color,
        $color-active,
        $icon-color-active,
        $border-color-active,
        $bg-color-active
      );
    }

    // Text colors
    .btn.btn-text-#{$name} {
      $color: $value;
      $icon-color: null;
      $bg-color: null;
      $border-color: null;

      $color-active: null;
      $icon-color-active: null;
      $border-color-active: null;
      $bg-color-active: null;

      @include button-custom-variant(
        $color,
        $icon-color,
        $border-color,
        $bg-color,
        $color-active,
        $icon-color-active,
        $border-color-active,
        $bg-color-active
      );
    }

    // Text colors active state
    .btn.btn-active-text-#{$name} {
      $color: null;
      $icon-color: null;
      $bg-color: null;
      $border-color: null;

      $color-active: $value;
      $icon-color-active: null;
      $border-color-active: null;
      $bg-color-active: null;

      @include button-custom-variant(
        $color,
        $icon-color,
        $border-color,
        $bg-color,
        $color-active,
        $icon-color-active,
        $border-color-active,
        $bg-color-active
      );
    }
  }
}

// Outline dashed style
.btn.btn-outline.btn-outline-dashed.btn-outline-default {
  border-width: 1px;
  border-style: dashed;

  $color: $light-inverse;
  $icon-color: $light-inverse;
  $border-color: $gray-300;
  $bg-color: null;

  $color-active: $primary;
  $icon-color-active: $primary;
  $border-color-active: $primary;
  $bg-color-active: $primary-light;

  @include button-custom-variant(
    $color,
    $icon-color,
    $border-color,
    $bg-color,
    $color-active,
    $icon-color-active,
    $border-color-active,
    $bg-color-active
  );
}
