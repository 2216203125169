//*-----------------------------------------------
//|   Color System
//-----------------------------------------------*/
$white: #ffffff !default;
$gray-100: #f6f8fa !default;
$gray-200: #f0f2f5 !default;
$gray-300: #e4e6ef !default;
$gray-400: #b6b6c4 !default;
$gray-500: #a2a6b8 !default;
$gray-600: #7e8299 !default;
$gray-700: #5e6278 !default;
$gray-800: #4f5058 !default;
$gray-900: #404254 !default;
$gray-1000: #121526 !default;
$gray-1100: #07080f !default;
$black: #000 !default;
$text-muted: $gray-500 !default;

$grays: () !default;
$grays: map-merge(
  (
    'black': $black,
    '100': $gray-100,
    '200': $gray-200,
    '300': $gray-300,
    '400': $gray-400,
    '500': $gray-500,
    '600': $gray-600,
    '700': $gray-700,
    '800': $gray-800,
    '900': $gray-900,
    '1000': $gray-1000,
    '1100': $gray-1100,
    'white': $white
  ),
  $grays
);

//*-----------------------------------------------
//|   Solid Colors
//-----------------------------------------------*/
$blue: #00b2ff !default;
$indigo: #b27bf7 !default;
$purple: #5710b2 !default;
$pink: #ff7697 !default;
$red: #f1416c !default;
$orange: #f59700 !default;
$yellow: #ffc700 !default;
$green: #20d489 !default;
$teal: #25e6d8 !default;
$cyan: #27bcfd !default;
$beige: #fbf9f6 !default;

//*-----------------------------------------------
//|   Contextual Colors
//-----------------------------------------------*/
// Dark
$dark: $gray-900 !default;
$dark-active: darken($dark, 3%) !default;
$dark-light: $gray-200 !default;
$dark-inverse: $white !default;

// Primary
$primary: $orange !default;
$primary-active: #e68f01 !default;
$primary-light: #fdefd8 !default;
$primary-inverse: $white !default;

// Secondary
$secondary: $gray-300 !default;
$secondary-active: $gray-400 !default;
$secondary-light: $gray-100 !default;
$secondary-inverse: $gray-800 !default;

// Light
$light: $gray-100 !default;
$light-active: $gray-300 !default;
$light-inverse: $gray-700 !default;
$lighten: rgba($light, 0.5);

// Success
$success: $green !default;
$success-active: #19b674 !default;
$success-light: #e1f8ee !default;
$success-inverse: $white !default;

// Info
$info: $purple !default;
$info-active: #41058e !default;
$info-light: #eee5ff !default;
$info-inverse: $white !default;

// Warning
$warning: $yellow !default;
$warning-active: #f1bc00 !default;
$warning-light: #fff4de !default;
$warning-inverse: $white !default;

// Danger
$danger: $red !default;
$danger-active: #d9214e !default;
$danger-light: #ffe2e5 !default;
$danger-inverse: $white !default;

// White
$white: $white !default;
$white-active: $gray-100 !default;
$white-inverse: $gray-600 !default;

// Blue
$blue: $blue !default;
$blue-active: #0098da !default;
$blue-light: #cbedff !default;
$blue-inverse: $white !default;

$theme-colors: () !default;
$theme-colors: map-merge(
  (
    'primary': $primary,
    'primary-light': $primary-light,
    'secondary': $secondary,
    'success': $success,
    'info': $info,
    'warning': $warning,
    'danger': $danger,
    'light': $light,
    'dark': $dark,
    'beige': $beige,
    'blue': $blue,
    'gray-100': $gray-100,
    'gray-200': $gray-200,
    'gray-300': $gray-300,
    'gray-400': $gray-400,
    'gray-500': $gray-500,
    'gray-600': $gray-600,
    'gray-700': $gray-700,
    'gray-800': $gray-800,
    'gray-900': $gray-900,
    'gray-1000': $gray-1000,
    'gray-1100': $gray-1100
  ),
  $theme-colors
);

// Hover colors
$theme-active-colors: () !default;
$theme-active-colors: map-merge(
  (
    'white': $white-active,
    'primary': $primary-active,
    'secondary': $secondary-active,
    'light': $light-active,
    'success': $success-active,
    'info': $info-active,
    'warning': $warning-active,
    'danger': $danger-active,
    'dark': $dark-active,
    'blue': $blue-active
  ),
  $theme-active-colors
);

// Inverse colors
$theme-inverse-colors: () !default;
$theme-inverse-colors: map-merge(
  (
    'white': $white-inverse,
    'primary': $primary-inverse,
    'secondary': $secondary-inverse,
    'light': $light-inverse,
    'success': $success-inverse,
    'info': $info-inverse,
    'warning': $warning-inverse,
    'danger': $danger-inverse,
    'dark': $dark-inverse,
    'blue': $blue-inverse
  ),
  $theme-inverse-colors
);

// Light colors
$theme-light-colors: () !default;
$theme-light-colors: map-merge(
  (
    'primary': $primary-light,
    'success': $success-light,
    'info': $info-light,
    'warning': $warning-light,
    'danger': $danger-light,
    'dark': $dark-light,
    'blue': $blue-light
  ),
  $theme-light-colors
);

// Text colors
$theme-text-colors: () !default;
$theme-text-colors: map-merge(
  (
    'white': $white,
    'primary': $primary,
    'secondary': $secondary,
    'light': $light,
    'success': $success,
    'info': $info,
    'warning': $warning,
    'danger': $danger,
    'dark': $dark,
    'blue': $blue,
    'muted': $text-muted,
    'gray-100': $gray-100,
    'gray-200': $gray-200,
    'gray-300': $gray-300,
    'gray-400': $gray-400,
    'gray-500': $gray-500,
    'gray-600': $gray-600,
    'gray-700': $gray-700,
    'gray-800': $gray-800,
    'gray-900': $gray-900,
    'gray-1000': $gray-1000,
    'gray-1100': $gray-1100
  ),
  $theme-text-colors
);
