.feature_item {
  background: #FFFFFF;
  border: 1px solid rgba(76, 76, 76, 0.05);
  border-radius: 15px;
  padding: 17px 20px;
  height: 60px;
  display: flex;
  justify-content: space-between;
}
.feature_area {
  background: rgba(246, 246, 246, 0.5) !important;
  border-radius: 15px;
  padding: 40px;
  min-height: 300px;
}
.input_limit {
  width: 60px;
  border-radius: 5px !important;
  padding: 2px 5px;
  border: 1px solid rgba(76, 76, 76, 0.20);
  box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.05);
  height: 24px;
}