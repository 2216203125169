/* -------------------------------------------------------------------------- */
/*   Main Content                                                                  */
/* -------------------------------------------------------------------------- */

.content {
  width: 100%;
  padding: 40px 21px;
  min-height: 100vh;
  overflow-y: scroll;
  padding-bottom: 80px;
}

/* -------------------------------------------------------------------------- */
/*   Dashboard                                                                  */
/* -------------------------------------------------------------------------- */

.dashboard-content {
  min-height: 100vh;
  overflow-y: auto;
  padding-bottom: 40px;
  padding-left: 80px;
  padding-right: 80px;
  padding-top: 40px;
  transition: all 0.3s;
  width: 100%;
  // margin-left: 350px;

  .filter-button {
    .btn {
      text-align: left;
      width: 100%;
      padding: 0.8rem 1.5rem !important;

      .svg-inline--fa {
        transition: 0.3s;
      }

      &:active,
      &:hover,
      &:focus {
        background: $primary-light !important;
        border: 1px dashed $primary !important;

        .svg-inline--fa {
          color: $primary;
        }
      }
    }
  }

  .table {
    thead {
      background: $beige;
      text-transform: uppercase;
      color: $gray-500;
      font-weight: $font-weight-bold;
      font-size: $font-size-sm;
      line-height: 2rem;
      // border-bottom: 1px solid $gray-300;

      tr {
        th {
          text-align: center;

          &:first-of-type {
            text-align: left;
          }
        }
      }
    }

    tbody {
      tr {
        border-color: $gray-200;
        line-height: 2.5rem;

        .badge.bg-secondary {
          color: $gray-700;
          text-transform: uppercase;
          font-weight: $font-weight-bold;
        }

        .btn {
          padding: 0.4rem 0.8rem !important;
          margin-right: 0.8rem;

          &:last-of-type {
            margin-right: 0;
          }
        }

        td {
          text-align: center;

          &:first-of-type {
            text-align: left;
          }
        }
      }
    }
  }
}

@include media-breakpoint-down(lg) {
  .dashboard-content {
    margin: 0;
    padding: 46px;
  }
}
