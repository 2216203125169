.wrap {
  text-align: center;
}
.loop {
  width: 50px;
  height: auto;
  margin: 0 auto;
}

.path {
  stroke-dasharray: 1000;
  stroke-dashoffset: 1000;
  animation: dash 5s linear alternate infinite;
}

body {
  background: #fff;
  display: grid;
  place-items: center;
  width: 100%;
}

@keyframes dash {
  from {
    stroke-dashoffset: 1000;
  }
  to {
    stroke-dashoffset: 0;
  }
}

.blink_me {
  font-size: 13px;
  color: #4c4c4c;
  padding: 5px;
  animation: blinker 1s linear infinite;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}
