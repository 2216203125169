.spec_checkbox_btn {
  background-color:#F0F0F0;
  border-radius: 50px;
  overflow: auto;
  display: inline-block;
  color: #4C4C4C;
  cursor: pointer;
  position: relative;
}
.spec_checkbox_btn label {
  display: block;
}
.spec_checkbox_btn input {
  position: absolute;
  top: -20px;
}
.spec_checkbox_btn span {
  text-align: center;
  display: block;
  padding: 3px 15px;
}
.spec_checkbox_btn input:checked + span, .spec_checkbox_btn input:disabled {
  background-color: #4C4C4C;
  color:#fff;
}