.dropdown-menu.show {
  background-color: white;
  margin-top: 10px !important;
  margin-bottom: 10px !important;
  /* margin-left: 15px; */
}

/* .dropdown-menu.show::before {
  display: block;
  content: '';
  position: relative;
  top: -26px;
  right: -152px;
  border-bottom: 6px solid white;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  width: fit-content;
} */

/* .user-info-container {
  display: flex;
  justify-content: center;
}

.user-info {
  display: inline-block;
} */

/* @media (min-width: 1400px) {
  .dropdown-menu.show::before {
    right: -138px;
  }
}

@media (max-width: 1200px) {
  .user-info-container {
    width: 100%;
    margin: auto;
    text-align: center;
    margin-top: 20px;
    align-items: start;
  }
  div[aria-labelledby='user-info'] {
    width: fit-content;
    left: 50% !important;
    margin-left: -135px !important;
  }
} */
