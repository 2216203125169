.header {
  height: 78px;
  border-bottom: 1px solid rgba(76, 76, 76, 0.1);
  position: sticky;
  top: 0;
  z-index: 1000;
  background: #fff;
}
.logo {
  height: 35px;
  margin-top: 21px;
}
