.sizeIconContainer {
  display: flex;
  flex-wrap: nowrap;
  gap: 20px;
}
.fitContent {
  width: fit-content !important;
}
.fullWidth {
  width: 100%;
  margin: 0 auto !important;
}
.loading {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  text-align: center;
  opacity: 0.7;
  background-color: #fff;
  z-index: 1100;
}
.hide {
  display: none !important;
}

.filterPane {
  height: 66px;
  padding: 12px 25px;
  border-radius: 15px;
  background: #fafafa;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
}

.filterDropDownContainer {
  display: flex;
  align-items: center;
  border-radius: 8px;
  border: 1px solid #d3d3d3;
  height: 40px;
}

.filterDropDownContainer > *:not(:last-child) {
  border-right: 1px solid #d3d3d3;
}

.filterDropDown {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 15px;
  cursor: pointer;
}

.dropDownItem {
  font-size: 13px;
}

.iconGroup {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
}

.overlay {
  display: flex;
  padding: 20px 8px;
  /* box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.05); */
  background-color: #4c4c4c;
  border-radius: 15px;
  z-index: 100;
  color: #fff;
}

.overlay.moreFilter {
  padding: 40px 50px;
  max-height: 440px;
}

.dropdownMenuContainer {
  padding: 5px 15px;
}

.moreFilterColumnContainer {
  max-height: 270px;
  overflow-y: auto;
}

.moreFilterOverlaySectionTitle {
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #f0f0f0;
}

.moreFilterButtonGroup {
  border-top: 1px solid #f0f0f0;
}

.overlayContent {
  display: flex;
  flex-direction: column;
}

.optionItem {
  padding: 0.25rem 1rem;
  border-radius: 0.475rem;
  margin-bottom: 0.5rem;
}

.optionItem:hover,
.selectedItem {
  background-color: #fff8d5;
  color: #4c4c4c;
}

.customDatePickerContainer {
  padding: 7px 15px;
  display: flex;
  align-items: center;
}

/* By column */
.columnItem {
  border-radius: 15px !important;
  background-color: #4c4c4c !important;
  border: 1px solid #4c4c4c !important;
  color: #fff !important;
}
