.icon_sorter_up,
.icon_sorter_down {
  width: 15px;
  height: 15px;
  background-color: #b3b3b3;
  -webkit-mask-image: url(../../../assets/img/icons_new/icon_arrow_up.svg);
  mask-image: url(../../../assets/img/icons_new/icon_arrow_up.svg);
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
}
.icon_sorter_down {
  -webkit-mask-image: url(../../../assets/img/icons_new/icon_arrow_down.svg);
  mask-image: url(../../../assets/img/icons_new/icon_arrow_down.svg);
}
.active.icon_sorter_up,
.active.icon_sorter_down {
  background-color: #4c4c4c;
}
