.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 0;
  padding-bottom: 0;
  border-radius: 15px;
  gap: 20px;
}
.image {
  width: 40px;
  height: auto;
}