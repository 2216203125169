.feature_area {
  background: rgba(246, 246, 246, 0.5) !important;
  border-radius: 15px;
  padding: 40px;
}
.feature_item {
  background: #FFFFFF;
  border: 1px solid rgba(76, 76, 76, 0.05);
  border-radius: 15px;
  padding: 17px 20px;
  height: 60px;
  display: flex;
  justify-content: space-between;
}
.table_row {
  padding: 20px 0;
  border-bottom: 1px solid #F0F0F0;
}
.mb_big {
  margin-bottom: 160px;
}