/*-----------------------------------------------
|   Menus
-----------------------------------------------*/

.menu {
  display: flex;
  position: fixed;
  flex-direction: column;
  right: 5.7rem;
  margin-top: 0.1rem;
  min-width: 270px;
  border-radius: get($menu, dropdown, border-radius);
  background-color: get($menu, dropdown, background-color);
  box-shadow: get($menu, dropdown, box-shadow);
  z-index: get($menu, dropdown, z-index);

  &.show {
    animation: menu-animation-fade-in #{get($menu, dropdown, animation-speed)} ease
        1,
      menu-animation-move-up #{get($menu, dropdown, animation-speed)} ease 1;
  }

  .menu-header {
    padding: get($menu, link, self, padding-y) get($menu, link, self, padding-x);
  }

  .menu-items {
    padding: 0.9rem 1.15rem;
  }

  .menu-items > a {
    display: block;
    padding: 0;
    margin: 0;
    color: $gray-700;
    font-size: $font-size-sm;
    font-weight: $font-weight-bolder;
    text-decoration: none;
    transition: 0.3s;

    div {
      padding: 0.8rem 1rem;
    }

    &:not(:last-child) {
      border-bottom: 1px solid $gray-200;
    }

    &:hover {
      color: $primary;
    }
  }
}

// Menu animations
@keyframes menu-animation-fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes menu-animation-move-up {
  from {
    margin-top: #{get($menu, dropdown, animation-move-offset)};
  }
  to {
    margin-top: 0;
  }
}
