/* prettier-ignore */
@use 'sass:math';
@import 'colors';
$variable-prefix: 'trustedsign-';

///*-----------------------------------------------
//|   Breakpoints
//-----------------------------------------------*/
// Define the maximum width of `.container` for different screen sizes.
// scss-docs-start container-max-widths
$container-max-widths: () !default;
$container-max-widths: map-merge(
  (
    sm: 540px,
    md: 720px,
    lg: 960px,
    xl: 1140px,
    xxl: 1480px
  ),
  $container-max-widths
);

$grid-breakpoints: () !default;
$grid-breakpoints: map-merge(
  (
    xs: 0,
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 1200px,
    xxl: 1400px
  ),
  $grid-breakpoints
);

//*-----------------------------------------------
//|   Spacing
//----------------------------------------------
$spacer: 1rem !default;
$spacers: () !default;

$spacers: (
  0: 0,
  1: (
    $spacer * 0.25
  ),
  // 3.5px
  2:
    (
      $spacer * 0.5
    ),
  // 7px;
  3:
    (
      $spacer * 0.75
    ),
  // 10.5px
  4:
    (
      $spacer * 1
    ),
  // 14px
  5:
    (
      $spacer * 1.25
    ),
  // 17.5px
  6:
    (
      $spacer * 1.5
    ),
  // 21px
  7:
    (
      $spacer * 1.75
    ),
  // 24.5px
  8:
    (
      $spacer * 2
    ),
  // 28px
  9:
    (
      $spacer * 2.25
    ),
  // 31.5px
  10:
    (
      $spacer * 2.5
    ),
  // 35px
  11:
    (
      $spacer * 2.75
    ),
  // 38.5px
  12:
    (
      $spacer * 3
    ),
  // 42px
  13:
    (
      $spacer * 3.25
    ),
  // 45.5px
  14:
    (
      $spacer * 3.5
    ),
  // 49px
  15:
    (
      $spacer * 3.75
    ),
  // 52.5px
  16:
    (
      $spacer * 4
    ),
  // 55px
  17:
    (
      $spacer * 4.25
    ),
  // 58.5px
  18:
    (
      $spacer * 4.5
    ),
  // 62px
  19:
    (
      $spacer * 4.75
    ),
  // 65.5px
  20:
    (
      $spacer * 5
    ),
  // 69px
);

//*-----------------------------------------------
//|   Position
//----------------------------------------------
// Define the edge positioning anchors of the position utilities.
$position-values: (
  0: 0,
  25: 25%,
  50: 50%,
  75: 75%,
  100: 100%
) !default;

//*-----------------------------------------------
//|   Gutter
//-----------------------------------------------*/
// Min contrast ratio
$min-contrast-ratio: 2 !default;

// Set the number of columns and specify the width of the gutters.
$grid-columns: 12 !default;
$grid-gutter-width: 1.5rem !default;
$grid-row-columns: 6 !default;

$gutters: (
  0: 0,
  1: (
    $spacer * 0.25
  ),
  // 3.5px
  2:
    (
      $spacer * 0.5
    ),
  // 7px;
  3:
    (
      $spacer * 0.75
    ),
  // 10.5px
  4:
    (
      $spacer * 1
    ),
  // 14px
  5:
    (
      $spacer * 1.25
    ),
  // 17.5px
  6:
    (
      $spacer * 1.5
    ),
  // 21px
  7:
    (
      $spacer * 1.75
    ),
  // 24.5px
  8:
    (
      $spacer * 2
    ),
  // 28px
  9:
    (
      $spacer * 2.25
    ),
  // 31.5px
  10:
    (
      $spacer * 2.5
    ),
  // 35px
) !default;

// Container padding
$container-padding-x: $grid-gutter-width !default;

//*-----------------------------------------------
//|   Options
//-----------------------------------------------*/
// Quickly modify global styling by enabling or disabling optional features.
$enable-shadows: true !default;
$enable-gradients: false !default;
$enable-negative-margins: true !default;

// Define common padding
$rounded-pill: 50rem !default;

$component-active-color: $primary-inverse !default;
$component-active-bg: $primary !default;
$component-hover-bg: $primary-light !default;
$component-hover-color: $primary !default;

$caret-width: 0.3em !default;
$caret-vertical-align: $caret-width * 0.85 !default;
$caret-spacing: $caret-width * 0.85 !default;

$transition-base: all 0.2s ease !default;
$transition-fade: opacity 0.15s linear !default;
$transition-collapse: height 0.35s ease !default;
$transition-link: color 0.2s ease, background-color 0.2s ease !default;
$transition-input: color 0.2s ease, background-color 0.2s ease !default;

//*-----------------------------------------------
//|   Shadow
//-----------------------------------------------*/
$box-shadow-xs: 0px 0px 30px rgba(25, 53, 80, 0.055) !default;
$box-shadow-sm: 0 0.1rem 1rem 0.25rem rgba($black, 0.05) !default;
$box-shadow: 0 0.5rem 1.5rem 0.5rem rgba($black, 0.075) !default;
$box-shadow-lg: 0 1rem 2rem 1rem rgba($black, 0.1) !default;

//*-----------------------------------------------
//|   Body
//-----------------------------------------------*/
$body-bg: #fbf9f6 !default;
$body-color: $gray-700 !default;

//*-----------------------------------------------
//|   Link
//-----------------------------------------------*/
$link-color: $primary !default;
$link-hover-color: darken($primary, 5%) !default;
$link-decoration: none !default;
$link-hover-decoration: underline !default;

// Darken percentage for links with `.text-*` class (e.g. `.text-success`)
$emphasized-link-hover-darken-percentage: 15% !default;

//*-----------------------------------------------
//|   Components
//-----------------------------------------------*/
// Border
$border-radius-sm: 0.275rem !default;
$border-radius: 0.475rem !default;
$border-radius-lg: 0.775rem !default;
$border-radius-xl: 1rem !default;

$border-color: $gray-200 !default;
$border-dashed-color: $gray-300 !default;
$border-width: 1px !default;

//*-----------------------------------------------
//|   Fonts Families
//-----------------------------------------------*/
$font-family-sans-serif: 'Poppins', -apple-system, BlinkMacSystemFont,
  'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji',
  'Segoe UI Emoji', 'Segoe UI Symbol' !default;

//*-----------------------------------------------
//|   Fonts
//-----------------------------------------------*/
$type-scale: 1.2;
$font-size-base: 1rem !default;

$font-size-lg: $font-size-base * 1.075 !default; // 14.04px
$font-size-sm: $font-size-base * 0.925 !default; // 12.025px

$font-weight-lighter: lighter !default;
$font-weight-light: 300 !default;
$font-weight-normal: 400 !default;
$font-weight-bold: 500 !default;
$font-weight-bolder: 600 !default;
$font-weight-black: 700 !default;

$font-weight-base: $font-weight-normal !default;

$h1-font-size: $font-size-base * 1.75 !default; // 22.75px;
$h2-font-size: $font-size-base * 1.5 !default; // 19.50px
$h3-font-size: $font-size-base * 1.35 !default; // 17.55px
$h4-font-size: $font-size-base * 1.25 !default; // 16.25px
$h5-font-size: $font-size-base * 1.15 !default; // 14.95px
$h6-font-size: $font-size-base * 1.075 !default; // 13.97px

$font-sizes: (
  1: $h1-font-size,
  // 22.75px
  2: $h2-font-size,
  // 19.50px
  3: $h3-font-size,
  // 17.55px
  4: $h4-font-size,
  // 16.25px
  5: $h5-font-size,
  // 14.95px
  6: $h6-font-size,
  // 13.95px
  7: $font-size-base * 0.95,
  // 12.35px
  8: $font-size-base * 0.85,
  // 11.05px
  9: $font-size-base * 0.75,
  // 9.75px
  10: $font-size-base * 0.5,
  // 6.50px
  base: $font-size-base,
  // 13px
  fluid: 100%,
  // 100%
  2x: $font-size-base * 2,
  // 26px
  2qx: $font-size-base * 2.25,
  // 29.25px
  2hx: $font-size-base * 2.5,
  // 32.5px
  2tx: $font-size-base * 2.75,
  // 35.75px
  3x: $font-size-base * 3,
  // 39px
  3qx: $font-size-base * 3.25,
  // 42.25px
  3hx: $font-size-base * 3.5,
  // 45.5px
  3tx: $font-size-base * 3.75,
  // 48.75px
  4x: $font-size-base * 4,
  // 52px
  4qx: $font-size-base * 4.25,
  // 55.25px
  4hx: $font-size-base * 4.5,
  // 58.5px
  4tx: $font-size-base * 4.75,
  // 61.75px
  5x: $font-size-base * 5,
  // 65px
  5qx: $font-size-base * 5.25,
  // 68.25px
  5hx: $font-size-base * 5.5,
  // 71.5px
  5tx: $font-size-base * 5.75 // 74.75px,
) !default;

$headings-margin-bottom: math.div($spacer, 2) !default;
$headings-font-family: null !default;
$headings-font-style: null !default;
$headings-font-weight: $font-weight-bolder !default;
$headings-line-height: 1.2 !default;
$headings-color: null !default;

$display-font-sizes: (
  1: 5rem,
  2: 4.5rem,
  3: 4rem,
  4: 3.5rem,
  5: 3rem,
  6: 2.5rem
) !default;

$display-font-weight: $font-weight-bolder !default;
$display-line-height: $headings-line-height !default;

// Line heights
$line-height-base: 1.5 !default;
$line-height-sm: 1.25 !default;
$line-height-lg: 1.75 !default;
$line-height-xl: 2 !default;
$line-height-xxl: 2.25 !default;

//*-----------------------------------------------
//|   Z-index
//-----------------------------------------------*/

$zindex-sticky: 1015 !default;

//*-----------------------------------------------
//|   Buttons and Forms
//-----------------------------------------------*/
// Shared variables that are reassigned to `$input-` and `$btn-` specific variables.
$input-btn-padding-y: 0.7rem !default;
$input-btn-padding-x: 1rem !default;
$input-btn-font-family: null !default;
$input-btn-font-size: $font-size-base * 1.05 !default;
$input-btn-line-height: $line-height-base !default;

$input-btn-focus-width: 0.25rem !default;
$input-btn-focus-color-opacity: 0.25 !default;
$input-btn-focus-color: rgba(
  $component-active-bg,
  $input-btn-focus-color-opacity
) !default;
$input-btn-focus-box-shadow: null !default;

$input-placeholder-color: $gray-500 !default;
$input-plaintext-color: $body-color !default;

$input-btn-padding-y-sm: 0.55rem !default;
$input-btn-padding-x-sm: 0.75rem !default;
$input-btn-font-size-sm: $font-size-sm !default;

$input-btn-padding-y-lg: 0.825rem !default;
$input-btn-padding-x-lg: 1.5rem !default;
$input-btn-font-size-lg: $font-size-base * 1.15 !default;

$input-btn-border-width: $border-width !default;

//*-----------------------------------------------
//|   Buttons
//-----------------------------------------------*/
// For each of Bootstrap's buttons, define text, background, and border color.
$btn-padding-y: $input-btn-padding-y !default;
$btn-padding-x: 1.5rem !default;
$btn-font-family: $input-btn-font-family !default;
$btn-font-size: $input-btn-font-size !default;
$btn-font-weight: $font-weight-bold !default;
$btn-line-height: $input-btn-line-height !default;
$btn-white-space: null !default; // Set to `nowrap` to prevent text wrapping

$btn-padding-y-sm: $input-btn-padding-y-sm !default;
$btn-padding-x-sm: 1.25rem !default;
$btn-font-size-sm: $input-btn-font-size-sm !default;

$btn-padding-y-lg: $input-btn-padding-y-lg !default;
$btn-padding-x-lg: 1.75rem !default;
$btn-font-size-lg: $input-btn-font-size-lg !default;

$btn-border-width: $input-btn-border-width !default;

$btn-box-shadow: null !default;
$btn-focus-width: $input-btn-focus-width !default;
$btn-focus-box-shadow: null !default;
$btn-disabled-opacity: 0.6 !default;
$btn-active-box-shadow: null !default;

$btn-link-color: $link-color !default;
$btn-link-hover-color: $link-hover-color !default;
$btn-link-disabled-color: $gray-600 !default;

// Allows for customizing button radius independently from global border radius
$btn-border-radius: $border-radius !default;
$btn-border-radius-sm: $border-radius !default;
$btn-border-radius-lg: $border-radius !default;

$btn-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
  border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out !default;

$btn-extended-variants: true !default;

//*-----------------------------------------------
//|   Forms
//-----------------------------------------------*/
$form-label-margin-bottom: 0.5rem !default;
$form-label-font-size: 1.05rem !default;
$form-label-font-weight: 600 !default;
$form-label-color: null !default;

$form-text-margin-top: 0.5rem !default;
$form-text-font-size: $font-size-sm !default;
$form-text-color: $text-muted !default;

$input-padding-y: $input-btn-padding-y !default;
$input-padding-x: $input-btn-padding-x !default;
$input-font-family: $input-btn-font-family !default;
$input-font-size: $input-btn-font-size !default;
$input-font-weight: $font-weight-bold !default;
$input-line-height: $input-btn-line-height !default;

$input-solid-bg: $gray-100 !default;
$input-solid-bg-focus: darken($gray-100, 4%) !default;
$input-solid-placeholder-color: $input-placeholder-color !default;
$input-solid-color: $gray-700 !default;

$input-bg: $white !default;
$input-disabled-bg: $gray-200 !default;
$input-readonly-bg: $input-bg !default;
$input-disabled-border-color: null !default;

$input-color: $body-color !default;
$input-border-color: $gray-300 !default;
$input-border-width: $input-btn-border-width !default;

$input-border-radius: $btn-border-radius !default;
$input-border-radius-sm: $btn-border-radius-sm !default;
$input-border-radius-lg: $btn-border-radius-lg !default;

$input-focus-border-color: $gray-400 !default;
$input-focus-color: $input-color !default;

$form-check-input-width: 1.75rem !default;
$form-check-input-width-sm: 1.3rem !default;
$form-check-input-width-lg: 2.25rem !default;
$form-check-input-bg-size: 60% 60% !default;
$form-check-min-height: $font-size-base * $line-height-base !default;
$form-check-padding-start: $form-check-input-width + 0.5rem !default;
$form-check-padding-left: $form-check-input-width + 0.5rem !default;
$form-check-margin-bottom: 0.125rem !default;
$form-check-label-color: null !default;
$form-check-label-cursor: null !default;
$form-check-transition: background-color 0.15s ease-in-out,
  background-position 0.15s ease-in-out, border-color 0.15s ease-in-out,
  box-shadow 0.15s ease-in-out !default;

$form-check-input-active-filter: brightness(90%) !default;

$form-check-input-bg: $body-bg !default;
$form-check-input-bg-solid: $gray-200 !default;
$form-check-input-border: 1px solid rgba(0, 0, 0, 0.25) !default;
$form-check-input-border-radius: 0.45em !default;
$form-check-radio-border-radius: 50% !default;
$form-check-input-focus-border: $input-focus-border-color !default;
$form-check-input-focus-box-shadow: none !default;

$form-check-input-checked-color: $component-active-color !default;
$form-check-input-checked-bg-color: $component-active-bg !default;
$form-check-input-checked-border-color: $component-active-bg !default;
$form-check-input-checked-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 13 11' width='13' height='11' fill='none'><path d='M11.0426 1.02893C11.3258 0.695792 11.8254 0.655283 12.1585 0.938451C12.4917 1.22162 12.5322 1.72124 12.249 2.05437L5.51985 9.97104C5.23224 10.3094 4.72261 10.3451 4.3907 10.05L0.828197 6.88335C0.50141 6.59288 0.471975 6.09249 0.762452 5.7657C1.05293 5.43891 1.55332 5.40948 1.88011 5.69995L4.83765 8.32889L11.0426 1.02893Z' fill='#{$form-check-input-checked-color}'/></svg>") !default;
$form-check-radio-checked-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='2' fill='#{$form-check-input-checked-color}'/></svg>") !default;

$form-check-input-indeterminate-color: $component-active-color !default;
$form-check-input-indeterminate-bg-color: $component-active-bg !default;
$form-check-input-indeterminate-border-color: $component-active-bg !default;
$form-check-input-indeterminate-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'><path fill='none' stroke='#{$form-check-input-indeterminate-color}' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/></svg>") !default;

$form-check-input-disabled-opacity: 0.5 !default;
$form-check-label-disabled-opacity: $form-check-input-disabled-opacity !default;
$form-check-btn-check-disabled-opacity: $btn-disabled-opacity !default;

$form-switch-color: rgba(0, 0, 0, 0.25) !default;
$form-switch-width: 3.25rem !default;
$form-switch-height: 2.25rem !default;
$form-switch-width-sm: 2.5rem !default;
$form-switch-height-sm: 1.5rem !default;
$form-switch-width-lg: 3.75rem !default;
$form-switch-height-lg: 2.75rem !default;

$form-switch-padding-start: $form-switch-width + 0.5rem !default;
$form-switch-padding-left: $form-switch-width + 0.5rem !default;
$form-switch-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='3' fill='#{$form-switch-color}'/></svg>") !default;
$form-switch-bg-image-solid: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='3' fill='#{$white}'/></svg>") !default;
$form-switch-border-radius: $form-switch-width !default;

$form-switch-focus-color: $input-focus-border-color !default;
$form-switch-focus-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='3' fill='#{$form-switch-focus-color}'/></svg>") !default;

$form-switch-checked-color: $component-active-color !default;
$form-switch-checked-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='3' fill='#{$form-switch-checked-color}'/></svg>") !default;
$form-switch-checked-bg-position: right center !default;

$form-check-inline-margin-right: 1rem !default;

$input-group-addon-bg: $gray-100 !default;

$form-select-indicator-color: $gray-600 !default;
$form-select-border-radius: $border-radius !default;

//*-----------------------------------------------
//|   Cards
//-----------------------------------------------*/
$card-border-color: $border-color !default;
$card-border-dashed-color: $border-dashed-color !default;
$card-cap-bg: $white !default;
$card-cap-padding-y: $spacer !default;
$card-cap-padding-x: 1.25rem !default;
$card-border-radius: $border-radius-lg !default;

$card-header-padding-y: 0.5rem !default;
$card-header-height: 40px !default;

// /*-----------------------------------------------
//|   Breadcrumbs
//-----------------------------------------------*/
$breadcrumb-divider-color: $gray-600 !default;
$breadcrumb-active-color: $gray-900 !default;
$breadcrumb-item-padding-x: 0.5rem !default;

// /*-----------------------------------------------
//|   Tooltip
//-----------------------------------------------*/
$tooltip-padding-y: 0.5rem !default;
$tooltip-font-size: map_get($font-sizes, '-1') !default;

//*-----------------------------------------------
//|   Badge
//-----------------------------------------------*/
$badge-font-size: 0.85rem !default;
$badge-font-size-sm: 0.85rem !default;
$badge-font-size-lg: 1rem !default;
$badge-font-weight: $font-weight-bolder !default;
$badge-color: $white !default;
$badge-padding-y: 0.5em !default;
$badge-padding-x: 0.85em !default;
$badge-border-radius: $border-radius !default;
$badge-size: 1.75rem !default;
$badge-size-sm: 1.5rem !default;
$badge-size-lg: 2rem !default;

// /*-----------------------------------------------
//|   Modal
//-----------------------------------------------*/
$modal-content-border-radius: $border-radius-lg !default;
$modal-content-bg: $white !default;

///*-----------------------------------------------
//|   List Group
//-----------------------------------------------*/
$list-group-color: var(--#{$variable-prefix}800);
$list-group-bg: var(--#{$variable-prefix}list-group-bg) !default;
$list-group-border-color: var(--#{$variable-prefix}border-color) !default;
$list-group-action-hover-color: var(
  --#{$variable-prefix}list-group-action-hover-color
) !default;
$list-group-hover-bg: var(--#{$variable-prefix}list-group-hover-bg) !default;
$list-group-action-active-bg: var(--#{$variable-prefix}200);

//*-----------------------------------------------
//|   Thumbnail
//-----------------------------------------------*/
$thumbnail-bg: var(--#{$variable-prefix}thumbnail-bg) !default;
$thumbnail-border-width: 3px !default;
$thumbnail-border-color: var(--#{$variable-prefix}thumbnail-bg) !default;

// /*-----------------------------------------------
//|   Breadcrumbs
//-----------------------------------------------*/
$breadcrumb-padding-x: 0 !default;
$breadcrumb-margin-bottom: 0 !default;
$breadcrumb-bg: 'transparent';
$breadcrumb-divider: quote('/') !default;

//*-----------------------------------------------
//|   Carousel
//-----------------------------------------------*/
$carousel-transition-duration: 0.8s !default;

// /*-----------------------------------------------
//|   Spinner
//-----------------------------------------------*/
$spinner-width-sm: 1.35rem !default;
$spinner-height-sm: $spinner-width-sm !default;

// /*-----------------------------------------------
//|   Separator
//-----------------------------------------------*/
$hr-color: $border-color !default;
$hr-opacity: 1 !default;

//*-----------------------------------------------
//|   Viewport Heights & Widths
//----------------------------------------------
$viewport-heights: () !default;
$viewport-heights: map-merge(
  (
    25: 25vh,
    50: 50vh,
    75: 75vh,
    100: 100vh
  ),
  $viewport-heights
);

$viewport-widths: () !default;
$viewport-widths: map-merge(
  (
    25: 25vw,
    50: 50vw,
    75: 75vw,
    100: 100vw
  ),
  $viewport-widths
);

$sizes: () !default;
$sizes: map-merge(
  (
    25: 25%,
    50: 50%,
    75: 75%,
    100: 100%,
    auto: auto
  ),
  $sizes
);

// /*-----------------------------------------------
//|   Footer
//-----------------------------------------------*/
$footer-height: 3.9875rem !default;
$responsive-footer-height: 0.625rem !default;

// /*-----------------------------------------------
//|   Avatar
//-----------------------------------------------*/
$avatars-dimension: () !default;
$avatars-dimension: map-merge(
  (
    's': toRem(20rem),
    'm': toRem(24rem),
    'l': toRem(28rem),
    'xl': toRem(32rem),
    '2xl': toRem(40rem),
    '3xl': toRem(56rem),
    '4xl': toRem(98rem),
    '5xl': toRem(168rem)
  ),
  $avatars-dimension
);

//*-----------------------------------------------
//|   Utilities
//-----------------------------------------------*/
$opacity-values: (
  0: 0,
  5: 0.05,
  10: 0.1,
  15: 0.15,
  20: 0.2,
  25: 0.25,
  50: 0.5,
  75: 0.75,
  100: 1
) !default;

//*-----------------------------------------------
//|   Scrollbar
//-----------------------------------------------*/
$scrollbar-bg: var(--#{$variable-prefix}scrollbar-bg) !default;
$simplebar-bg: $scrollbar-bg !default;

//*-----------------------------------------------
//|   Menu
//-----------------------------------------------*/
$menu-box-shadow: 0px 0px 50px 0px rgba(82, 63, 105, 0.15) !default;
$menu-hover-bg: rgba($gray-100, 0.8) !default;

$menu: (
  dropdown: (
    box-shadow: $menu-box-shadow,
    z-index: 105,
    background-color: $white,
    border-radius: $border-radius,
    animation: true,
    animation-speed: 0.3s,
    animation-move-offset: 0.8rem
  ),
  accordion: (
    indention: 1rem,
    arrow-transition: transform 0.3s ease
  ),
  item: (
    self: (
      padding-y: 0.15rem,
      padding-x: 0
    )
  ),
  link: (
    self: (
      padding-y: 0.65rem,
      padding-x: 1rem,
      bg-color: (
        hover: $menu-hover-bg,
        show: $menu-hover-bg,
        active: $menu-hover-bg
      )
    ),
    icon: (
      width: 2rem,
      space: 0.5rem
    ),
    bullet: (
      width: 1.25rem,
      space: 0.5rem
    ),
    badge: (
      space: 0.5rem
    ),
    arrow: (
      height: 0.8rem,
      width: 0.8rem,
      space: 0.5rem
    )
  )
) !default;
