/* ============================================== MyToolTip Styling ============================================== */
.tooltip > .tooltip-inner {
  background-color: #fff8d4;
  color: #000;
  border-radius: 10px;
  max-width: 658px;
  width: auto;
  padding: 8px 20px;
  margin: 0;
  border-color: #fff8d4 !important;
  text-align: left;
}

.tooltip.show {
  opacity: 1 !important;
}

.bs-tooltip-top .tooltip-arrow::before {
  border-top-color: #fff8d4 !important;
}

.bs-tooltip-bottom .tooltip-arrow::before {
  border-bottom-color: #fff8d4 !important;
}

.bs-tooltip-start .tooltip-arrow::before {
  border-left-color: #fff8d4 !important;
}

.bs-tooltip-end .tooltip-arrow::before {
  border-right-color: #fff8d4 !important;
}