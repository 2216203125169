//*-----------------------------------------------
//|   Wizard Form
//-----------------------------------------------*/
.wizard-nav button:nth-child(1) {
  z-index: 1;
}
.wizard-nav button:nth-child(2) {
  z-index: 2;
}
.wizard-nav button:nth-child(3) {
  z-index: 3;
}
.wizard-nav button:nth-child(4) {
  z-index: 4;
}
.wizard-nav button:nth-child(5) {
  z-index: 5;
}
.wizard-nav button:nth-child(6) {
  z-index: 6;
}
.wizard-nav button:nth-child(7) {
  z-index: 7;
}
.wizard-nav {
  // display: flex;
  // justify-content: center;
  margin-top: 28px;
  margin-bottom: 20px;
  height: 28px;
  overflow-x: auto;
  .wizard-wrap {
    background: rgba(76, 76, 76, 0.05);
    border-radius: 50px;
    padding: 4px;
    overflow: hidden;
    height: 28px;
    line-height: 28px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .wizard-nav-item {
    padding: 0.75rem 0;
    transition: $transition-link;
    margin-bottom: 0.9rem;
    display: flex;
    align-items: center;

    &:hover {
      text-decoration: none;
    }

    .nav-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      transition: $transition-link;
      min-width: 46px;
      height: 46px;
      border-radius: 12px;
      background-color: $gray-100;
      margin-right: 1rem;

      .nav-check {
        display: none;
        font-size: 1.4rem;
      }

      span {
        font-weight: $font-weight-bolder;
        color: $gray-700;
        font-size: $font-size-lg;
      }
    }

    .nav-label {
      color: $gray-700;
      font-weight: $font-weight-bolder;
      font-size: $font-size-base;
      justify-content: center;
      margin-bottom: 0.05rem;
    }

    // Current
    &.current {
      transition: $transition-link;

      .nav-icon {
        transition: $transition-link;
        background-color: $primary-light;

        .nav-check {
          color: $primary;
          display: none;
        }

        span {
          color: $primary;
        }
      }

      .nav-label {
        color: $dark;
      }
    }

    // Completed
    // &.current:last-child,
    &.completed {
      .nav-icon {
        transition: $transition-link;
        background-color: $primary-light;

        .nav-check {
          color: $primary;
          display: inline-block;
        }

        span {
          display: none;
        }
      }

      .nav-label {
        color: $text-muted;
      }
    }
  }

  .wizard-nav-item:last-child {
    margin-bottom: 0;
  }
}

.wizard-dynamic-form {
  list-style-type: none;
  padding: 0;

  li {
    background-color: $gray-100;
    padding: 25px 30px;
    border-radius: $border-radius-lg;
    margin-bottom: 20px;

    .recipient-number {
      width: 55px;
    }
  }

  .icon-delete {
    cursor: pointer;

    &:hover {
      color: $gray-900;
    }
  }
}

.file-list {
  list-style-type: none;
  padding-left: 0;
  min-height: 80px;
}

//*-----------------------------------------------
//|   Drag n Drop Container
//-----------------------------------------------*/
// Variables
$column-width: 22.1875rem;
$page-header-height: 4.3125rem;
$column-header-height: 2.5625rem;
$column-footer-height: 2.8125rem;
$horizontal-scroll-height: 0.9375rem;

.dragdrop-container {
  padding-bottom: map-get($spacers, 1);
}

.list-column {
  width: $column-width;
  min-height: 80px;
  white-space: normal;
  margin-right: 1rem;
  background: $gray-100;
  border-radius: $border-radius-lg;
  display: block;
  width: 100%;
}

.list-header {
  display: flex;
  align-items: center;
  border-bottom: 1px solid $gray-300;
  padding: map_get($spacers, 2) $card-spacer-x;
  border-top-left-radius: $border-radius-lg;
  border-top-right-radius: $border-radius-lg;
}

.list-container {
  outline: none;
  overflow-y: auto;
  max-height: 75vh;
  padding: map_get($spacers, 2) $card-spacer-x;
}

.list-item {
  cursor: pointer;
  user-select: none;
  outline: none;
  margin: 0.7rem 0;
}

.list-item-card {
  border: 1px dashed $dark !important;
  border-radius: 0 !important;
  font-size: map_get($font-sizes, '9');
}

// Update Draggable default styles
.draggable--is-dragging * {
  cursor: grab !important;
}

.draggable-source--is-dragging {
  box-shadow: none !important;
  border-radius: $border-radius-lg;
  * {
    opacity: 0;
  }
}

.draggable-mirror {
  z-index: 1100;
  .list-item-card {
    transform: rotate(-2deg);
  }
}

button.btn-circle {
  width: 2.5rem;
  height: 2.5rem;
  padding: 0;
  background-color: $gray-200;
  text-shadow: none;
  border-radius: 50%;
  box-shadow: $box-shadow-sm;
  opacity: 0.5;
  @include hover-focus {
    opacity: 1 !important;
    background-color: $gray-300;
  }
}

.icon-title {
  width: 1.25rem;
  text-align: center;
  margin-right: map_get($spacers, 3);
}

div[data-collapse],
.draggable--original:first-child {
  + .list-item {
    margin-top: 0;
  }
}

//*-----------------------------------------------
//|   File Configuration
//-----------------------------------------------*/
.file-editor {
  display: flex;
  width: 100%;
  height: 100%;

  &__sidebar {
    height: 100%;
    width: 420px;
    padding: 2rem 1.2rem;
    flex-direction: column;
    align-items: flex-start;
    border-right: 1px solid rgba(0, 0, 0, 0.1);
    overflow: hidden;

    .annotations {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;

      .annotation {
        flex-direction: column;
        justify-content: flex-start;
        align-items: stretch;
        padding: 1.3rem 0;
        border-top: 1px solid $gray-300;

        .annotation-heading {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 0.4rem;
        }
      }
    }
  }

  .pspdf-container {
    width: 100%;
    height: 90vh;
  }
  .pdf-review-file {
    background: #f6f6f6;
    height: 43px;
    line-height: 43px;
    margin: -2rem -3.75rem 1.2rem;
    padding: 0 3.75rem;
  }
}
