.search {
  position: relative;
  display: flex;
  align-items: center;
}

.searchInput {
  padding: 20px 35px;
  gap: 25px;
  width: 100%;
  min-width: 50vw;
  height: 70px;
  background: #ffffff;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.05);
  border: none;
  border-radius: 15px;

  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
  color: #9e9e9e;
}

.icon {
  position: absolute;
  right: 25px;
  width: 20px;
  height: 20px;
}
