//
//
// custom-variables.scss
//
// Place your own variable overrides here, these will override any Bootstrap and theme variables.

// For use when page background-color is primary
.btn-light-primary-border {
  border: 1px solid transparent !important;

  &:hover {
    border: 1px solid #ffffff !important;
  }
}

$body-bg: #fff;
$body-color: #4c4c4c;
$font-family-sans-serif: 'Manrope', sans-serif;
$navbar-light-active-color: '#F59700';
$btn-border-radius: 50px;
$btn-border-radius-lg: 50px;
$btn-border-radius-sm: 50px;
$btn-padding-x: 27px;
$btn-padding-y: 9px;
$btn-padding-y-sm: 3px;
$btn-padding-x: 15px;
$btn-padding-x-lg: 38px;
$btn-padding-y-lg: 15px;
$btn-font-size-lg: 18px;
$btn-font-size: 14px;
$btn-font-size-sm: 12px;
$btn-font-family: 'Manrope', sans-serif;
// $border-color: #F59700;
$btn-border-width: 2px;
$form-check-input-width: 1.1rem;
$form-check-input-width-sm: 1.1rem;
$form-switch-width: 2.5rem;
